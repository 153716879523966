$primary: #008752;
$primary-opacity: #f2f8f5;
$secondary: #411564;
$secondary-100: #35124d;
$secondary-200: #280d39;
$secondary-300: #f5f3f5;
$light-orange: #fef0e9;
$success: #b1d34b;
$danger: #ca0401;
$warning: #ffcb08;
$info: #17a2b8;
$light: #f8f9fa;
$dark: #343a40;
$white: #ffffff;
$transparent: transparent;
$yellow-light: #fff0c7;
$pink: #ed1651;
$purple: #a3238e;
$green-dark-100: #0a5f55;
$green-dark-200: #00401a;
$orange-dark: #682d00;
$gray-light: #c4cbcf;
$gray-000: #ddd;
$gray-100: #5b5c65;
$gray-200: #222222;
$gray-300: #e6e7e8;
$gray-400: #f2f3f4;
$black: #000000;
$button-solid-primary-primary: #008752;
$dark-contrast-color: #210202;