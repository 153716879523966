.LinksFooterDesk {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  &__links-footer-container {
    @media screen and (min-width: 768px) {
      max-width: 239px;
    }
  }

  &__no-classname {
    color: #fff;
    font-size: 1.125rem;
    font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
  }

  &__no-classname {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__no-classname {
    line-height: 1;
    padding: 6px 0;

    a {
      font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
      &:hover {
        text-decoration: underline;
      }
    }
    a,
    i {
      color: var(--white);
      font-size: 0.778rem;
      &.icone-diagonal-arrow-right-up::before {
        font-size: 0.778rem;
      }
    }
  }
}
