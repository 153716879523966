.accordion {
  width: 100%;
  height: auto;

  &-faq,
  &-faq-2 {
    border-color: var(--gray-form);
    border-style: solid;
    border-width: 1px 0;

    .accordion-wrapper {
      border-bottom: 1px solid var(--gray-form);

      &:last-of-type {
        border-bottom: none;
      }
    }
    details {
      &[open] {
        summary {
          color: var(--primary);
          &::after {
            content: '\e91e';
            font-family: "icomoon";
            float: right;
            margin: auto 8px;
            transform: rotate(180deg);
            @media screen and (min-width: 768px) {
              margin: auto 16px auto auto;
            }
          }
        }
        h4, p {
          margin-bottom: 32px;
        }
      }

      + details {
        border-top: none;
      }

      summary {
        font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
        font-size: 1.125rem;
        line-height: 1.38;
        color: var(--gray-200);
        padding: 16px 0;

        @media screen and (min-width: 768px) {
          font-size: 1.334rem;
        }

        &::marker, &::-webkit-details-marker {
          display: none;
          content: "";
        }

        &::after {
          content: '\e91e';
          font-family: "icomoon";
          float: right;
          margin: auto 8px;
          transition: transform 200ms ease;
          @media screen and (min-width: 768px) {
            margin: auto 16px auto auto;
          }
        }

        h3, p {
          max-width: 80%;
          display: inline-block;

          @media screen and (min-width: 768px) {
            max-width: 95%;
          }
        }
      }
      h3, p {
        font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
        font-size: 1rem;
        line-height: 1.39;
        letter-spacing: 0.25px;
        color: var(--gray-100);
      }
    }
  }

  &-footer {
    details {
      border-bottom: 1px solid var(--green-dark-100);
      &[open] {
        summary {
          color: var(--white);
          &::after {
            content: '\e91e';
            font-family: "icomoon";
            margin: auto 8px;
            transform: rotate(180deg);
          }
        }
      }

      + details {
        border-top: none;
      }

      summary {
        font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
        font-size: 1.125rem;
        line-height: 1.39;
        letter-spacing: 0.25px;
        color: var(--white);
        padding: 12px 0 12px 8px;
        list-style: none;

        &::marker {
          display: none;
        }

        &::after {
          content: '\e91e';
          font-family: "icomoon";
          float: right;
          margin: auto 8px;
          transition: transform 200ms ease;
        }
      }

      nav {
        ul {
          list-style: none;
          padding-inline-start: 12px;
          li {
            padding: 4px 0;
            a {
              color: var(--white);
              font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
              font-size: 1rem;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}
