.ModalLogin {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 100px;
  left: 0px;
  z-index: 2;

  @media screen and (min-width: 768px) {
    top: 80px;
    left: -20px;
  }

  > .container {
    justify-content: flex-end !important;
  }

  &__modal-box {
    max-width: 293px;
    width: 100%;
    background: var(--primary);
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin: 0 20px;

    .invalid-feedback {
      margin-bottom: 5px;
      color: var(--dark-contrast-color);
    }

    @media screen and (min-width: 768px) {
      padding: 24px 16px;
      margin: 20px;
    }
  }

  &__modal-form {
    .form-label {
      font-size: 1rem;
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      line-height: 1.38;
      letter-spacing: 0.15px;
      color: var(--white) !important;
      display: block;
      margin: 0;
      padding: 0;

      + input {
        margin-top: 4px;
        margin-bottom: 19px;
      }
    }
    input {
      margin: 0;
      padding: 10px 8px;
      width: 100%;
      height: 40px;
      font-size: 0.778rem;
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--white);
      background-color: var(--transparent);
      border: solid 1px var(--white) !important;
      border-radius: 2px;

      &:active,
      &:focus {
        background-color: var(--transparent);
        outline: solid 1px var(--primary);
        color: var(--white);
      }
    }
    button {
      width: 100%;
      height: 40px;
      margin: 24px 0;
      margin-bottom: 10px !important;
      padding: 8px 16px;
      border-radius: 24px;
      display: block;
      background: var(--white);
      border: none;
      color: var(--primary);
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      font-size: 1rem;

      @media screen and (min-width: 768px) {
        height: 48px;
        padding: 12px 16px;
      }

      &:hover {
        color: var(--primary);
        background-color: var(--white);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__modal-suggestions {
    width: 100%;
    height: auto;
    text-align: center;

    a {
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      color: var(--white);
      display: block;

      &:first-of-type {
        padding-bottom: 8px;
      }
    }
  }

  &__modal-container {
    padding: 20px;
  }

  &--logged {
    .overlay {
      position: fixed;

      @media screen and (min-width: 768px) {
        display: none;
      } // @media screen and (min-width: 768px)
    } // .overlay

    .container {
      position: relative;
      z-index: 3;
    } // .container

    .ModalLogin__modal-box {
      width: 100%;
      max-width: 220px;
      padding: 0;
      background-color: transparent;
      overflow: hidden;
    } // .ModalLogin__modal-box

    .ModalLogin__content {
      padding: 24px 0;
      background-color: var(--primary);
      text-align: center;

      a {
        display: block;
        margin-bottom: 14px;
        font-size: 1rem;
        color: var(--white);

        &:last-child {
          margin-bottom: 0;
        } // &:last-child

        @media screen and (min-width: 768px) {
          font-size: 0.888rem;
        } // @media screen and (min-width: 768px)
      } // a
    } // ModalLogin__content

    .ModalLogin__footer {
      padding: 25px 0;
      background-color: var(--green-dark-100);
      text-align: center;

      button {
        background-color: transparent;
        border: none;
        font-size: 0.875rem;
        color: var(--white);

        i {
          margin-left: 5px;
          vertical-align: middle;

          &:before {
            font-size: 1.1rem;
          } // &:before
        } // i

        @media screen and (min-width: 768px) {
          font-size: 0.777rem;

          i {
            &:before {
              font-size: 1.5rem;
            } // &:before
          } // i
        } // @media screen and (min-width: 768px)
      } // button
    } // .ModalLogin__footer
  } // &--logged
}
