.card {
  box-shadow: none;
  border: solid 1px transparent;

  &:hover,
  &.active {
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
  }

  &.card-primary {
    background-color: var(--primary);
    border: solid 1px var(--primary);
    color: var(--white);

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--primary), var(--primary));
      color: var(--white);
    }

    p,
    span,
    p > i,
    span > i {
      color: var(--white);
    }

    svg {
      fill: var(--white);

      g,
      path {
        fill: var(--white);

        path {
          fill: var(--white);

          &:last-of-type {
            fill: var(--white);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--primary);

      p,
      span,
      p > i,
      span > i {
        color: var(--primary);
      }

      svg {
        fill: var(--primary);

        g,
        path {
          fill: var(--primary);

          path {
            fill: var(--primary);

            &:last-of-type {
              fill: var(--primary);
            }
          }
        }
      }

      &:hover,
      &.active {
        background-color: var(--primary);
        color: var(--white);
        background-image: none;

        p,
        span,
        i,
        p > i,
        span > i {
          color: var(--white) !important;
        }

        svg {
          fill: var(--white);

          g,
          path {
            fill: var(--white);

            path {
              fill: var(--white);

              &:last-of-type {
                fill: var(--white);
              }
            }
          }
        }
      }
    }
  }

  &.card-secondary {
    background-color: var(--secondary);
    border: solid 1px var(--secondary);
    color: var(--white);

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--secondary), var(--secondary));
      color: var(--white);
    }

    p,
    span,
    p > i,
    span > i {
      color: var(--white);
    }

    svg {
      fill: var(--white);

      g,
      path {
        fill: var(--white);

        path {
          fill: var(--white);

          &:last-of-type {
            fill: var(--white);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--secondary);

      p,
      span,
      p > i,
      span > i {
        color: var(--secondary) !important;
      }

      svg {
        fill: var(--secondary);

        g,
        path {
          fill: var(--secondary);

          path {
            fill: var(--secondary);

            &:last-of-type {
              fill: var(--secondary);
            }
          }
        }
      }

      &:hover,
      &.active {
        background-color: var(--secondary);
        color: var(--white);
        background-image: none;

        p,
        span,
        a,
        p > i,
        span > i,
        a > i {
          color: var(--white);
        }

        svg {
          fill: var(--white);

          g,
          path {
            fill: var(--white);

            path {
              fill: var(--white);

              &:last-of-type {
                fill: var(--white);
              }
            }
          }
        }
      }
    }
  }

  &.card-success,
  &.card-sucess,
  &.green-light {
    background-color: var(--success);
    border: solid 1px var(--success);
    color: var(--green-dark-200);

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--success), var(--success));
      color: var(--green-dark-200);
    }

    p,
    span,
    p > i,
    span > i {
      color: var(--green-dark-200);
    }

    svg {
      fill: var(--green-dark-200);

      g,
      path {
        fill: var(--green-dark-200);

        path {
          fill: var(--green-dark-200);

          &:last-of-type {
            fill: var(--green-dark-200);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--success);

      p,
      span,
      p > i,
      span > i {
        color: var(--success) !important;
      }

      &:hover,
      &.active {
        background-color: var(--success);
        color: var(--green-dark-200);
        background-image: none;

        p,
        span,
        a,
        p > i,
        span > i,
        a > i {
          color: var(--green-dark-200) !important;
        }
      }
    }
  }

  &.card-purple {
    background-color: var(--purple);
    border: solid 1px var(--purple);
    color: var(--white);

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--purple), var(--purple));
      color: var(--white);
    }

    p,
    span,
    p > i,
    span > i {
      color: var(--white);
    }

    svg {
      fill: var(--white);

      g,
      path {
        fill: var(--white);

        path {
          fill: var(--white);

          &:last-of-type {
            fill: var(--white);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--purple);

      p,
      span,
      p > i,
      span > i {
        color: var(--purple);
      }

      svg {
        fill: var(--purple);

        g,
        path {
          fill: var(--purple);

          path {
            fill: var(--purple);

            &:last-of-type {
              fill: var(--purple);
            }
          }
        }
      }

      &:hover,
      &.active {
        background-color: var(--purple);
        color: var(--white);
        background-image: none;

        p,
        span,
        a,
        p > i,
        span > i,
        a > i {
          color: var(--white) !important;
        }

        svg {
          fill: var(--white);

          g,
          path {
            fill: var(--white);

            path {
              fill: var(--white);

              &:last-of-type {
                fill: var(--white);
              }
            }
          }
        }
      }
    }
  }

  &.card-orange {
    background-color: var(--orange);
    border: solid 1px var(--orange);
    color: var(--dark-contrast-color);

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--orange), var(--orange));
      color: var(--dark-contrast-color);
    }

    p,
    span,
    p > i,
    span > i,
    .btn {
      color: var(--dark-contrast-color) !important;
    }

    .btn {
      border: solid 1px var(--dark-contrast-color);
    }

    svg {
      fill: var(--dark-contrast-color);

      g,
      path {
        fill: var(--dark-contrast-color);

        path {
          fill: var(--dark-contrast-color);

          &:last-of-type {
            fill: var(--dark-contrast-color);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--orange);

      p,
      span,
      p > i,
      span > i {
        color: var(--orange) !important;
      }

      svg {
        fill: var(--orange);

        g,
        path {
          fill: var(--orange);

          path {
            fill: var(--orange);

            &:last-of-type {
              fill: var(--orange);
            }
          }
        }
      }

      &:hover,
      &.active {
        background-color: var(--orange);
        color: var(--dark-contrast-color);
        background-image: none;

        p,
        span,
        a,
        p > i,
        span > i,
        a > i {
          color: var(--dark-contrast-color) !important;
        }

        svg {
          fill: var(--dark-contrast-color);

          g,
          path {
            fill: var(--dark-contrast-color);

            path {
              fill: var(--dark-contrast-color);

              &:last-of-type {
                fill: var(--dark-contrast-color);
              }
            }
          }
        }
      }
    }
  }

  &.card-orange-dark {
    background-color: var(--orange-dark);
    border: solid 1px var(--orange-dark);
    color: var(--white);

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--orange-dark), var(--orange-dark));
      color: var(--white);
    }

    p,
    span,
    p > i,
    span > i,
    .btn {
      color: var(--white);
    }

    .btn {
      border: solid 1px var(--white);
    }

    svg {
      fill: var(--white);

      g,
      path {
        fill: var(--white);

        path {
          fill: var(--white);

          &:last-of-type {
            fill: var(--white);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--orange-dark);

      p,
      span,
      p > i,
      span > i {
        color: var(--orange-dark);
      }

      svg {
        fill: var(--orange-dark);

        g,
        path {
          fill: var(--orange-dark);

          path {
            fill: var(--orange-dark);

            &:last-of-type {
              fill: var(--orange-dark);
            }
          }
        }
      }

      &:hover,
      &.active {
        background-color: var(--orange-dark);
        color: var(--white);
        background-image: none;

        p,
        span,
        a,
        p > i,
        span > i,
        a > i {
          color: var(--white);
        }

        svg {
          fill: var(--white);

          g,
          path {
            fill: var(--white);

            path {
              fill: var(--white);

              &:last-of-type {
                fill: var(--white);
              }
            }
          }
        }
      }
    }
  }

  &.card-pink {
    background-color: var(--pink);
    border: solid 1px var(--pink);
    color: var(--dark-contrast-color);

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--pink), var(--pink));
      color: var(--dark-contrast-color);
    }

    p,
    span,
    a,
    p > i,
    span > i,
    a > i,
    .btn {
      color: var(--dark-contrast-color);
    }

    .btn {
      border: solid 1px var(--dark-contrast-color);
    }

    svg {
      fill: var(--dark-contrast-color);

      g,
      path {
        fill: var(--dark-contrast-color);

        path {
          fill: var(--dark-contrast-color);

          &:last-of-type {
            fill: var(--dark-contrast-color);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--pink);

      p,
      span,
      a,
      p > i,
      span > i,
      a > i {
        color: var(--pink);
      }

      svg {
        fill: var(--pink);

        g,
        path {
          fill: var(--pink);

          path {
            fill: var(--pink);

            &:last-of-type {
              fill: var(--pink);
            }
          }
        }
      }

      &:hover,
      &.active {
        background-color: var(--pink);
        color: var(--dark-contrast-color);
        background-image: none;

        p,
        span,
        a,
        p > i,
        span > i,
        a > i {
          color: var(--dark-contrast-color);
        }

        svg {
          fill: var(--dark-contrast-color);

          g,
          path {
            fill: var(--dark-contrast-color);

            path {
              fill: var(--dark-contrast-color);

              &:last-of-type {
                fill: var(--dark-contrast-color);
              }
            }
          }
        }
      }
    }
  }

  &.card-only-image {
    margin: 0;
    padding: 0;
    overflow: hidden;
    cursor: default;
    border: none;

    &:hover,
    &.active {
      box-shadow: none;
    }

    img {
      border-radius: 16px;
      min-height: 100%;
      min-width: 100%;

      @media screen and (min-width: 768px) {
        max-width: none;
        max-height: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.card-green-dark {
    background-color: var(--green-dark-100);
    border: solid 1px var(--green-dark-100);
    color: var(--white) !important;

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--green-dark-100), var(--green-dark-100));
      color: var(--white) !important;
    }

    p,
    span,
    p > i,
    span > i {
      color: var(--white);
    }

    svg {
      fill: var(--white);

      g,
      path {
        fill: var(--white);

        path {
          fill: var(--white);

          &:last-of-type {
            fill: var(--white);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--green-dark-100);

      p,
      span,
      i,
      a,
      .btn,
      p > i,
      span > i,
      a > i,
      .btn > i {
        color: var(--green-dark-100);
      }

      svg {
        fill: var(--green-dark-100);

        g,
        path {
          fill: var(--green-dark-100);

          path {
            fill: var(--green-dark-100);

            &:last-of-type {
              fill: var(--green-dark-100);
            }
          }
        }
      }

      &:hover,
      &.active {
        background-color: var(--green-dark-100);
        color: var(--white);
        background-image: none;

        p,
        span,
        i,
        a,
        .btn,
        p > i,
        span > i,
        a > i,
        .btn > i {
          color: var(--white);
        }

        svg {
          fill: var(--white);

          g,
          path {
            fill: var(--white);

            path {
              fill: var(--white);

              &:last-of-type {
                fill: var(--white);
              }
            }
          }
        }
      }
    }
  }

  &.card-green-dark-200 {
    background-color: var(--green-dark-200);
    border: solid 1px var(--green-dark-200);
    color: var(--white) !important;

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--green-dark-200), var(--green-dark-200));
      color: var(--white) !important;
    }

    p,
    span,
    a,
    p > i,
    span > i,
    a > i {
      color: var(--white);
    }

    svg {
      fill: var(--white);

      g,
      path {
        fill: var(--white);

        path {
          fill: var(--white);

          &:last-of-type {
            fill: var(--white);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--green-dark-200);

      p,
      span,
      i,
      a,
      .btn,
      p > i,
      span > i,
      a > i,
      .btn > i {
        color: var(--green-dark-200);
      }

      svg {
        fill: var(--green-dark-200);

        g,
        path {
          fill: var(--green-dark-200);

          path {
            fill: var(--green-dark-200);

            &:last-of-type {
              fill: var(--green-dark-200);
            }
          }
        }
      }

      &:hover,
      &.active {
        background-color: var(--green-dark-200);
        color: var(--white);
        background-image: none;

        p,
        span,
        i,
        a,
        .btn,
        p > i,
        span > i,
        a > i,
        .btn > i {
          color: var(--white);
        }

        svg {
          fill: var(--white);

          g,
          path {
            fill: var(--white);

            path {
              fill: var(--white);

              &:last-of-type {
                fill: var(--white);
              }
            }
          }
        }
      }
    }
  }

  &.card-warning {
    background-color: var(--warning);
    border: solid 1px var(--warning);
    color: var(--dark);

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--warning), var(--warning));
      color: var(--dark);
    }

    p,
    span,
    i,
    a,
    .btn,
    p > i,
    span > i,
    a > i,
    .btn > i {
      color: var(--dark);
    }

    svg {
      fill: var(--dark);

      g,
      path {
        fill: var(--dark);

        path {
          fill: var(--dark);

          &:last-of-type {
            fill: var(--dark);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--warning);

      p,
      span,
      i,
      a,
      .btn,
      p > i,
      span > i,
      a > i,
      .btn > i {
        color: var(--warning);
      }

      svg {
        fill: var(--warning);

        g,
        path {
          fill: var(--warning);

          path {
            fill: var(--warning);

            &:last-of-type {
              fill: var(--warning);
            }
          }
        }
      }

      &:hover,
      &.active {
        background-color: var(--warning);
        color: var(--dark);
        background-image: none;

        p,
        span,
        i,
        a,
        .btn,
        p > i,
        span > i,
        a > i,
        .btn > i {
          color: var(--dark);
        }

        svg {
          fill: var(--dark);

          g,
          path {
            fill: var(--dark);

            path {
              fill: var(--dark);

              &:last-of-type {
                fill: var(--dark);
              }
            }
          }
        }
      }
    }
  }

  &.card-danger {
    background-color: var(--danger);
    border: solid 1px var(--danger);
    color: var(--white);

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--danger), var(--danger));
      color: var(--white);
    }

    p,
    span,
    i,
    a,
    .btn,
    p > i,
    span > i,
    a > i,
    .btn > i {
      color: var(--white);
    }

    svg {
      fill: var(--white);

      g,
      path {
        fill: var(--white);

        path {
          fill: var(--white);

          &:last-of-type {
            fill: var(--white);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--danger);

      p,
      span,
      a,
      .btn,
      p > i,
      span > i,
      .btn > i {
        color: var(--danger);
      }

      svg {
        fill: var(--danger);

        g,
        path {
          fill: var(--danger);

          path {
            fill: var(--danger);

            &:last-of-type {
              fill: var(--danger);
            }
          }
        }
      }

      &:hover,
      &.active {
        background-color: var(--danger);
        color: var(--white);
        background-image: none;

        p,
        span,
        i,
        a,
        .btn,
        p > i,
        span > i,
        a > i,
        .btn > i {
          color: var(--white);
        }

        svg {
          fill: var(--white);

          g,
          path {
            fill: var(--white);

            path {
              fill: var(--white);

              &:last-of-type {
                fill: var(--white);
              }
            }
          }
        }
      }
    }
  }

  &.card-transparent {
    background-color: var(--transparent);
    border: solid 1px var(--white);
    color: var(--white);

    &:hover,
    &.active {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to bottom, var(--transparent), var(--transparent));
      color: var(--white);
    }

    p,
    span,
    i,
    a,
    .btn,
    p > i,
    span > i,
    a > i,
    .btn > i {
      color: var(--white);
    }

    svg {
      fill: var(--white);

      g,
      path {
        fill: var(--white);

        path {
          fill: var(--white);

          &:last-of-type {
            fill: var(--white);
          }
        }
      }
    }

    &.outline {
      background-color: var(--transparent);
      color: var(--white);

      p,
      span,
      i,
      a,
      .btn,
      p > i,
      span > i,
      a > i,
      .btn > i {
        color: var(--white);
      }

      &:hover,
      &.active {
        background-color: var(--transparent);
        color: var(--white);
        background-image: none;

        p,
        span,
        i,
        a,
        .btn,
        p > i,
        span > i,
        a > i,
        .btn > i {
          color: var(--white);
        }
      }
    }
  }
}
