@import "variables";

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icone-edit {
    &:before {
      content: $icone-edit;
      font-size: 1rem;
      vertical-align: middle;
    }
  }

  &.icone-logout {
    &:before {
      content: $icone-logout;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-whatsapp {
    &:before {
      content: $icone-whatsapp;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-download {
    &:before {
      content: $icone-download;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.eye {
    &:before {
      content: $eye;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.eye-blocked {
    &:before {
      content: $eye-blocked;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-eye {
    &:before {
      content: $icone-eye;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-eye-blocked {
    &:before {
      content: $icone-eye-blocked;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-expand {
    &:before {
      content: $icone-expand;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-arrow-down {
    &:before {
      content: $icone-arrow-down;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-pin {
    &:before {
      content: $icone-pin;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-alert {
    &:before {
      content: $icone-alert;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-briefcase {
    &:before {
      content: $icone-briefcase;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-calendar {
    &:before {
      content: $icone-calendar;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-checkmark {
    &:before {
      content: $icone-checkmark;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-close-circle {
    &:before {
      content: $icone-close-circle;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-contrast {
    &:before {
      content: $icone-contrast;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-credit-card {
    &:before {
      content: $icone-credit-card;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-lock {
    &:before {
      content: $icone-lock;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-options {
    &:before {
      content: $icone-options;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-people {
    &:before {
      content: $icone-people;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-person {
    &:before {
      content: $icone-person;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-person-add {
    &:before {
      content: $icone-person-add;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-person-delete {
    &:before {
      content: $icone-person-delete;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-person-done {
    &:before {
      content: $icone-person-done;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-person-remove {
    &:before {
      content: $icone-person-remove;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-phone {
    &:before {
      content: $icone-phone;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-phone-call {
    &:before {
      content: $icone-phone-call;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-plus {
    &:before {
      content: $icone-plus;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-search {
    &:before {
      content: $icone-search;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-talk {
    &:before {
      content: $icone-talk;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-control-checkmark {
    &:before {
      content: $icone-control-checkmark;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-close {
    &:before {
      content: $icone-close;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-minus {
    &:before {
      content: $icone-minus;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-smile {
    &:before {
      content: $icone-smile;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-global-network {
    &:before {
      content: $icone-global-network;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-email {
    &:before {
      content: $icone-email;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-arrow-left {
    &:before {
      content: $icone-arrow-left;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-arrow-right {
    &:before {
      content: $icone-arrow-right;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-arrow-upward {
    &:before {
      content: $icone-arrow-upward;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-arrowhead-left {
    &:before {
      content: $icone-arrowhead-left;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-arrowhead-right {
    &:before {
      content: $icone-arrowhead-right;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-chevron-down {
    &:before {
      content: $icone-chevron-down;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-chevron-left {
    &:before {
      content: $icone-chevron-left;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-chevron-right {
    &:before {
      content: $icone-chevron-right;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-chevron-up {
    &:before {
      content: $icone-chevron-up;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-close-book {
    &:before {
      content: $icone-close-book;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-diagonal-arrow-right-up {
    &:before {
      content: $icone-diagonal-arrow-right-up;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-menu {
    &:before {
      content: $icone-menu;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-facebook {
    &:before {
      content: $icone-facebook;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-instagram {
    &:before {
      content: $icone-instagram;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-linkedin {
    &:before {
      content: $icone-linkedin;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-twitter {
    &:before {
      content: $icone-twitter;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-youtube {
    &:before {
      content: $icone-youtube;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-play-arrow {
    &:before {
      content: $icone-play-arrow;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-printer {
    &:before {
      content: $icone-printer;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-plus-circle {
    &:before {
      content: $icone-plus-circle;
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  &.icone-customer-service {
    &:before {
      content: "\e935";
    }
  }
  &.icone-change {
    &:before {
      content: "\e936";
    }
  }
}
