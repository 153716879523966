@import "./../../../styles/utils";

.page-steps {
  width: 100%;
  overflow: hidden;

  .slick-track {
    display: flex;
    margin-left: -1rem;
  }

  .slick-list {
    padding: 0 !important;
  }

  &__simple-big-buttons {
    gap: 1rem;
  }

  .simple-big-button {
    width: 100%;
    min-width: 100px;
    height: 100px;
    border-radius: 16px;

    @media screen and (min-width: 768px) {
      width: 127px;
      height: auto;
    }
  }

  &__submit {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__custom-modal {
    &-container {
      padding: 0 20px;
      color: var(--dark-contrast-color);
    }
  }

  .select-profile {
    border-color: var(--gray-form) !important;
    border-width: 0px 0px 1px 0px !important;
    font-family: "UnimedSans-2020-regular", Arial, sans-serif;
    font-size: remMobile(18px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: var(--primary);
    padding: 10px 0;

    @media screen and (min-width: 768px) {
      font-size: remDesktop(24px);
    }
  }

  .text {
    font-family: "UnimedSans-2020-regular", Arial, sans-serif;
    font-size: remMobile(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: var(--gray-100);
    padding: 0 15px;

    @media screen and (min-width: 768px) {
      font-size: remDesktop(14px);
      padding: initial;
    }
  }
}

.login-secondary-validated {
  .form-check-label {
    a {
      color: var(--primary) !important;
    }
    span {
      color: var(--gray-100) !important;
    }
  }
}
