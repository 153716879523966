@import './../../../../../styles/utils';

.session-expired {
  border: 1px solid var(--gray-form);
  border-radius: 16px;

  @media screen and (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto;
  }

  &__header {
    .title {
      font-family: "UnimedSlab-2020-Bold", Arial, sans-serif;
      font-size: remMobile(18px);
      line-height: 25px;
      letter-spacing: 0;
      text-align: center;
      color: var(--primary);
  
      @media screen and (min-width: 768px) {
        font-size: remDesktop(24px);
        line-height: 33px;
      }
    }
  }
  
  &__body {
    padding: 24px 0;
    text-align: center;

    @media screen and (min-width: 768px) {
      padding: 32px 0;
    }

    .text {
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      font-size: remMobile(16px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0.15px;
      color: var(--gray-100);

      @media screen and (min-width: 768px) {
        font-size: remDesktop(18px);
        letter-spacing: 0.25px;
        color: var(--gray-100);
      }
    }

    .icon {
      margin: 0 auto 24px;
      display: block;
      width: 53px;
      height: 48px;
      fill: var(--purple);

      g,
      path {
        fill: var(--purple);

        path {
          fill: var(--purple);

          &:last-of-type {
            fill: var(--purple);
          }
        }
      }

      @media screen and (min-width: 768px) {
        margin: 0 auto 32px;
        width: 64px;
        height: 58px;
      }
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 32px 0;

    @media screen and (min-width: 768px) {
      margin: 32px 0;
    }
  }
}
