@import './../../../styles/utils';

.card-icon-link {
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 12px 8px;
  min-height: 57px;
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (min-width: 960px) {
    padding: 12px 16px;
    min-height: 89px;
    max-height: 89px;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    overflow: visible;
    fill: white;

    path {
      fill: white;

      &:last-of-type {
        fill: white;
      }
    }

    @media screen and (min-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }

  &:hover {
    background: var(--white);
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
    .card-icon-link__action {
      color: var(--primary);
    }

    svg {
      fill: var(--primary);

      path {
        fill: var(--primary);

        &:last-of-type {
          fill: var(--primary);
        }
      }
    }
  }

  .card-icon-link__action {
    margin: auto 0;
    color: var(--white);
    font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
    font-size: remMobile(16px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;

    @media screen and (min-width: 768px) {
      font-size: remDesktop(20px);
      line-height: 1.65;
    }
  }
}
