@font-face {
  font-family: 'UnimedSans-2020-Bold';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Bd.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Bd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Bd.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Bd.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Bd.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-BoldCondensed';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdCd.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdCd.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdCd.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-BoldCondensedItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdCdIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdCdIt.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-BoldItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BdIt.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-Book';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Bk.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Bk.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Bk.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Bk.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Bk.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-BookCondensed';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkCd.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkCd.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkCd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-BookCondensedItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkCdIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkCdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-BookItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BkIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-BlkItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BlkIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BlkIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BlkIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BlkIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BlkIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-BlkItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BlkIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BlkIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BlkIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BlkIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-BlkIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-Lg';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Lg.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Lg.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Lg.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Lg.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Lg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-LgCondensed';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgCd.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgCd.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgCd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-LgCondensedItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgCdIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgCdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-LgItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-LgIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-Regular';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Rg.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Rg.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Rg.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Rg.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Rg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-RegularCondensed';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgCd.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgCd.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgCd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-RegularCondensedItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgCdIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgCdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-RegularItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-RgIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-SemiBold';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Sb.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Sb.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Sb.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Sb.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Sb.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-SemiBoldCondensed';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbCd.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbCd.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbCd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-SemiBoldCondensedItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbCdIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbCdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-SemiBoldItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-SbIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-Xb';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Xb.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Xb.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Xb.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Xb.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-Xb.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-XbCondensed';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XbCd.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XbCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XbCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XbCd.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XbCd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-XbCondensedItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XbCdIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XbCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XbCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XbCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XbCdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSans-2020-XbItalic';
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XdIt.eot');
  src: url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XdIt.woff') format('woff'),
  url('../fonts/Unimed\ Sans\ 2020/UnimedSans2020-XdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

// UNIMED SERIF
@font-face {
  font-family: 'UnimedSerif-2020-Bold';
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Bd.eot');
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Bd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Bd.ttf') format('truetype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Bd.woff') format('woff'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Bd.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSerif-2020-BoldItalic';
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-BdIt.eot');
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-BdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-BdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-BdIt.woff') format('woff'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-BdIt.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSerif-2020-Book';
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Bk.eot');
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Bk.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Bk.ttf') format('truetype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Bk.woff') format('woff'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Bk.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSerif-2020-BookItalic';
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-BkIt.eot');
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-BkIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-BkIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-BkIt.woff') format('woff'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-BkIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSerif-2020-Regular';
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Rg.eot');
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Rg.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Rg.ttf') format('truetype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Rg.woff') format('woff'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-Rg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSerif-2020-RegularItalic';
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-RgIt.eot');
  src: url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-RgIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-RgIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-RgIt.woff') format('woff'),
  url('../fonts/Unimed\ Serif\ 2020/UnimedSerif2020-RgIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

//UNIMED SLAB
@font-face {
  font-family: 'UnimedSlab-2020-Bold';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Bd.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Bd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Bd.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Bd.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Bd.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-BoldCondensed';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdCd.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdCd.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdCd.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-BoldCondensedItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdCdIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdCdIt.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-BoldItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BdIt.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-Book';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Bk.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Bk.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Bk.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Bk.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Bk.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-BookCondensed';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkCd.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkCd.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkCd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-BookCondensedItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkCdIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkCdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-BookItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BkIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-BlkItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BlkIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BlkIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BlkIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BlkIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BlkIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-BlkItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BlkIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BlkIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BlkIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BlkIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-BlkIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-Lg';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Lg.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Lg.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Lg.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Lg.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Lg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-LgCondensed';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgCd.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgCd.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgCd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-LgCondensedItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgCdIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgCdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-LgItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-LgIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-Regular';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Rg.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Rg.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Rg.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Rg.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Rg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-RegularCondensed';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgCd.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgCd.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgCd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-RegularCondensedItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgCdIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgCdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-RegularItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-RgIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-SemiBold';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Sb.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Sb.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Sb.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Sb.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Sb.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-SemiBoldCondensed';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbCd.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbCd.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbCd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-SemiBoldCondensedItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbCdIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbCdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-SemiBoldItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-SbIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-Xb';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Xb.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Xb.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Xb.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Xb.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-Xb.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-XbCondensed';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbCd.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbCd.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbCd.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbCd.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbCd.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-XbCondensedItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbCdIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbCdIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbCdIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbCdIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbCdIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: 'UnimedSlab-2020-XbItalic';
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbIt.eot');
  src: url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbIt.eot#iefix') format('embedded-opentype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbIt.ttf') format('truetype'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbIt.woff') format('woff'),
  url('../fonts/Unimed\ Slab\ 2020/UnimedSlab2020-XbIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

//icons
@font-face {
  font-family: "icomoon";
  src: url('../fonts/icons/icomoon.eot');
  src: url('../fonts/icons/icomoon.eot#iefix') format('embedded-opentype'),
  url('../fonts/icons/icomoon.ttf') format('truetype'),
  url('../fonts/icons/icomoon.woff') format('woff'),
  url('../fonts/icons/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
