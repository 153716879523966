@import "./../../../../../styles/utils";

.change-password {
  &__criteria {
    padding: 0;

    li {
      list-style-type: none;
    }
  }

  &__submit {
    width: 100%;
  }

  .password-input, .confirm-password-input {
    position: relative;
    margin-bottom: 23px;
    @media screen and (min-width: 768px) {
      margin-bottom: 30px;
    }

    .password-visible {
      position: absolute;
      top: 42px;
      right: 5px;
      cursor: pointer;

      .icone-eye {
        &::before {
          font-size: 1.5rem;
          color: var(--gray-form);
        }

        &.show {
          &::before {
            color: var(--primary);
          }
        }
      }

      @media screen and (min-width: 768px) {
        font-size: remDesktop(14px);
        top: 45px;
      }
    }
  }
}
