.lazyload-wrapper  {
  height: 100%;
}

.ImageComponent {
  width: auto;
  max-width: 100%;
  max-height: 100%;

  &.size290x290 {
    width: 290px;
    height: 100%;
  }

  &.full-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
