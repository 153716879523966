$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icone-edit: "\e931";
$icone-logout: "\e932";
$icone-whatsapp: "\e930";
$icone-download: "\e92e";
$eye: "\e9ce";
$eye-blocked: "\e9d1";
$icone-eye: "\e92f";
$icone-eye-blocked: "\e9d1";
$icone-expand: "\e92d";
$icone-arrow-down: "\e92c";
$icone-pin: "\e92b";
$icone-alert: "\e900";
$icone-briefcase: "\e901";
$icone-calendar: "\e902";
$icone-checkmark: "\e903";
$icone-close-circle: "\e904";
$icone-contrast: "\e905";
$icone-credit-card: "\e906";
$icone-lock: "\e907";
$icone-options: "\e908";
$icone-people: "\e909";
$icone-person: "\e90a";
$icone-person-add: "\e90b";
$icone-person-delete: "\e90c";
$icone-person-done: "\e90d";
$icone-person-remove: "\e90e";
$icone-phone: "\e90f";
$icone-phone-call: "\e910";
$icone-plus: "\e911";
$icone-search: "\e912";
$icone-talk: "\e913";
$icone-control-checkmark: "\e914";
$icone-close: "\e915";
$icone-minus: "\e916";
$icone-smile: "\e917";
$icone-email: "\e918";
$icone-arrow-left: "\e919";
$icone-arrow-right: "\e91a";
$icone-arrow-upward: "\e91b";
$icone-arrowhead-left: "\e91c";
$icone-arrowhead-right: "\e91d";
$icone-chevron-down: "\e91e";
$icone-chevron-left: "\e91f";
$icone-chevron-right: "\e920";
$icone-chevron-up: "\e921";
$icone-close-book: "\e922";
$icone-diagonal-arrow-right-up: "\e923";
$icone-menu: "\e924";
$icone-facebook: "\e925";
$icone-instagram: "\e926";
$icone-linkedin: "\e927";
$icone-twitter: "\e928";
$icone-youtube: "\e929";
$icone-play-arrow: "\e92a";
$icone-printer: "\e933";
$icone-plus-circle: "\e934";
$icone-customer-service: "\e935";
$icone-change: "\e936";
$icone-global-network: "\e997";
