@import "./../../../../../styles/utils";

.login-form {

  .login-form-content {
    .login-form-area {
      .password-input {
        position: relative;
        margin-bottom: 23px;
        @media screen and (min-width: 768px) {
          margin-bottom: 30px;
        }

        #password {
          padding-right: 40px;
        }

        .password-visible {
          position: absolute;     
          cursor: pointer;
          right: 4px;
          margin-top: 43px;

          .icone-eye {
            &::before {
              font-size: 1.5rem;
              color: var(--gray-form);
            }

            &.show {
              &::before {
                color: var(--primary);
              }
            }
          }

          @media screen and (min-width: 768px) {
            font-size: remDesktop(14px);
          }
        }
      }

      .submit-button {
        font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
        font-size: remMobile(16px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        justify-content: center;
        margin-bottom: 23px;

        @media screen and (min-width: 768px) {
          font-size: remDesktop(18px);
          line-height: 1.39;
          margin-bottom: 30px;
        }
      }
    }

    .forgot {
      text-align: center;
      button {
        border: none;
        background: transparent;
        font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
        font-size: remMobile(14px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: var(--secondary);
  
        @media screen and (min-width: 768px) {
          font-size: remDesktop(14px);
        }
      }
    }
  }
}