@import './../../../../../styles/utils';

.login-email-validated {
  .content {
    .login-email-validated-body {
      padding: 24px 0;

      @media screen and (min-width: 768px) {
        padding: 32px 0;
      }
    }

    .container {
      width: initial;
      padding: 0 1rem;

      .text {
        font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
        font-size: remMobile(16px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0.15px;
        text-align: center;
        color: var(--gray-100);

        @media screen and (min-width: 768px) {
          font-size: remDesktop(18px);
          letter-spacing: 0.25px;
        }
      }
    }
  }
}
