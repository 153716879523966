@charset "utf-8";

@import './default'; //>>descomentar para pegar as fontes no repositório<<
@import './slick'; //>>descomentar para pegar as cores carrossel no repositório<<

// COLORS
$theme-colors: (
  'primary': #008752,
  'primary-opacity': #f2f8f5,
  'secondary': #411564,
  'secondary-100': #35124d,
  'secondary-200': #280d39,
  'secondary-300': #f5f3f5,
  'secondary-400': #230b36,
  'secondary-500': #2d0e45,
  'secondary-600': #371154,
  'orange': #f26d0c,
  'light-orange': #fef0e9,
  'success': #b1d34b,
  'sucess': #b1d34b,
  'danger': #ca0401,
  'warning': #ffcb08,
  'info': #17a2b8,
  'light': #f8f9fa,
  'dark': #343a40,
  'dark-contrast-color': #210202,
  'white': #ffffff,
  'transparent': transparent,
  'yellow-light': #fff0c7,
  'pink': #ed1651,
  'light-purple': #fff0fd,
  'purple': #a3238e,
  'green-dark-100': #0a5f55,
  'green-dark-200': #00401a,
  'green-dark-300': #0b2b09,
  'green-dark-400': #016155,
  'green-dark-500': #00554a,
  'orange-dark': #682d00,
  'gray-light': #c4cbcf,
  'gray-form': #928f8f,
  'gray-000': #ddd,
  'gray-100': #5b5c65,
  'gray-200': #222222,
  'gray-300': #e6e7e8,
  'gray-400': #f2f3f4,
  'gray-500': #fbfbfb,
  'black': #000000,
  'button-solid-primary-primary': #00995d,
  'egg-shell': #fff0c2,
);

// TEXT
$font-family-sans-serif: 'UnimedSans-2020-Regular', Arial, sans-serif;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@import '~bootstrap/scss/bootstrap';

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.section-spacing-first {
  padding: 2.5rem 0 1rem;

  @media screen and (min-width: 768px) {
    padding: 64px 0 32px 0;
  }
}

.section-spacing {
  padding: 20px 0;

  @media screen and (min-width: 768px) {
    padding: 32px 0;
  }
}

.section-spacing-last {
  padding: 20px 0 40px 0;

  @media screen and (min-width: 768px) {
    padding: 32px 0 64px 0;
  }
}

.contrast {
  filter: invert(1) hue-rotate(180deg);
}

html,
body {
  font-size: 16px;
  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a:hover {
  text-decoration: none;
}

.max-line {
  &-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    /* autoprefixer: off */
  }
  &-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* autoprefixer: off */
  }
  &-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    /* autoprefixer: off */
  }
  &-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    /* autoprefixer: off */
  }
  &-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    /* autoprefixer: off */
  }
  &-6 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    /* autoprefixer: off */
  }
  &-7 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    /* autoprefixer: off */
  }
}

button {
  border: none;
  background-color: transparent;
}

//btn override
.btn {
  display: flex;
  align-items: center;
  word-break: break-word;
  &-sm {
    border-radius: 15px;
    line-height: 1rem;
    font-size: 0.777rem;
  }
  &-md {
    border-radius: 19px;
    line-height: 1.1rem;
    font-size: 0.888rem;
  }
  &-lg {
    border-radius: 30px;
    line-height: 1.2;
    font-size: 0.9rem;

    @media screen and (min-width: 768px) {
      border-radius: 24px;
      line-height: 1.3;
      font-size: 1rem;
    }
  }
}

//form override
.form-control {
  border-radius: 2px;
  border: solid 1px var(--gray-light);
  padding: 10px 8px;
  color: var(--gray-100);
  font-size: 0.875rem;
  font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;

  &:focus-within,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(38 153 108 / 50%);
  }

  &:active {
    border: solid 1px var(--primary);
    box-shadow: none;
  }
  &:disabled {
    border: 1px solid var(--gray-light);
    background: var(--gray-000);
  }
  &.success {
    border: 1px solid var(--success);
  }
  &.warning {
    border: 1px solid var(--warning);
  }
  &.danger {
    border: 1px solid var(--danger);
  }
}

.overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1;

  &--light {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.title {
  &-book {
    &-lg {
      font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
      font-size: 1.625rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 2.333rem;
      }
    }
    &-md {
      font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
      font-size: 1.375rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 1.778rem;
      }
    }
    &-sm {
      font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
      font-size: 1.25rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 1.556rem;
      }
    }
    &-xs {
      font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
      font-size: 1.125rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 1.333rem;
      }
    }
  }

  &-semibold {
    &-lg {
      font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
      font-size: 1.375rem;
      line-height: 1.36;

      @media screen and (min-width: 768px) {
        font-size: 2.333rem;
      }
    }
    &-md {
      font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
      font-size: 1.375rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 1.778rem;
      }
    }
    &-sm {
      font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
      font-size: 1.25rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 1.556rem;
      }
    }
    &-xs {
      font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
      font-size: 1.125rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 1.333rem;
      }
    }
  }

  &-bold {
    &-lg {
      font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
      font-size: 1.625rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 2.333rem;
      }
    }
    &-md {
      font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
      font-size: 1.375rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 1.778rem;
      }
    }
    &-sm {
      font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
      font-size: 1.25rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 1.556rem;
      }
    }
    &-xs {
      font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
      font-size: 1.125rem;
      line-height: 1.38;

      @media screen and (min-width: 768px) {
        font-size: 1.333rem;
      }
    }
  }
}

.subtitle-bold {
  &-lg {
    font-family: 'UnimedSans-2020-Bold', Arial, sans-serif;
    font-size: 1.625rem;
    line-height: 1.38;

    @media screen and (min-width: 768px) {
      font-size: 2.333rem;
    }
  }
  &-md {
    font-family: 'UnimedSans-2020-Bold', Arial, sans-serif;
    font-size: 1.375rem;
    line-height: 1.38;

    @media screen and (min-width: 768px) {
      font-size: 1.778rem;
    }
  }
  &-sm {
    font-family: 'UnimedSans-2020-Bold', Arial, sans-serif;
    font-size: 1.25rem;
    line-height: 1.38;

    @media screen and (min-width: 768px) {
      font-size: 1.556rem;
    }
  }
}

.hero-title {
  font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
  font-size: 1.625rem;
  line-height: 1.38;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 2.333rem;
    line-height: 1.38;
  }
}

.breadcrumbz {
  position: absolute;
}

.page-title {
  &-lg {
    font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
    font-size: 1.675rem;
    line-height: 1.38;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      font-size: 2.333rem;
      line-height: 1.38;
    }
  }
  &-md {
    font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
    font-size: 1.375rem;
    line-height: 1.36;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      font-size: 1.777rem;
      line-height: 1.38;
    }
  }

  &-sm {
    font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
    font-size: 1.25rem;
    line-height: 1.36;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      font-size: 1.668rem;
      line-height: 1.38;
    }
  }
}

.container-title {
  &-lg {
    font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
    font-size: 1.375rem;
    line-height: 1.36;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      font-size: 1.777rem;
      line-height: 1.38;
    }
  }
  &-md {
    font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
    font-size: 1.25rem;
    line-height: 1.36;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      font-size: 1.662rem;
      line-height: 1.38;
    }
  }
  &-sm {
    font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 1.36;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      font-size: 1.556rem;
      line-height: 1.38;
    }
  }
}

//card override
.card-img.only-media {
  border-radius: 16px;
  border: transparent;
}

.only-media {
  border-radius: 16px;
  border: none;
}

.nav-link {
  padding: 0;
  display: unset;
  font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
}

.external {
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.vertical {
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.h {
  &1 {
    font-size: 4.5rem;
    line-height: 5rem;
  }
  &2 {
    font-size: 3.62rem;
    line-height: 4rem;
  }
  &3 {
    font-size: 2.75rem;
    line-height: 3rem;
  }
  &4 {
    font-size: 2.18rem;
    line-height: 2.5rem;
  }
  &5 {
    font-size: 1.62rem;
    line-height: 2rem;
  }
  & {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

h1 {
  font-size: 4.5rem;
  line-height: 5rem;
}

h2 {
  font-size: 3.62rem;
  line-height: 4rem;
}

h3 {
  font-size: 2.75rem;
  line-height: 3rem;
}

h4 {
  font-size: 2.18rem;
  line-height: 2.5rem;
}

h5 {
  font-size: 1.62rem;
  line-height: 2rem;
}

h6 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

p,
.p,
span,
.span {
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 0;
}

summary {
  list-style: none;
}

.attendance-bg {
  position: relative;
  background-image: url('../assets/img/footer.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  .overlay {
    z-index: 0;
  }

  .banner {
    position: relative;
    width: 100%;
    height: 735px;
    object-fit: cover;
    @media screen and (min-width: 768px) {
      height: 658px;
    }
    @media screen and (min-width: 992px) {
      height: 595px;
    }
  }

  > .navbar {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 3;
  }
}

.full-bg {
  position: relative;
  .banner {
    position: relative;
    width: 100%;
    height: 281px;
    object-fit: cover;
    @media screen and (min-width: 768px) {
      height: 520px;
    }
  }

  > .navbar,
  .navbar-position {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 3;
    width: 100%;
  }

  > .container {
    position: absolute;
    top: 61px;
    left: 0;
    bottom: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      top: 122px;
    }
  }

  &-lg {
    position: relative;
    .banner {
      position: relative;
      width: 100%;
      height: 381px;
      object-fit: cover;
      @media screen and (min-width: 768px) {
        max-height: 724px;
        height: 73vh;
        min-height: 450px;
      }
    }

    > .navbar,
    .navbar-position {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 3;
      width: 100%;
    }

    > .container {
      position: absolute;
      top: 112px;
      left: 0;
      bottom: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 2;
      display: flex;
      flex-direction: column;
    }
  }
}

.text {
  &-small {
    font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
    font-size: 0.888rem;
  }

  &-book {
    font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
    font-size: 1.25rem;
  }
}

.copyright {
  font-size: 0.775rem;
  font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
}

.box-wrapper {
  max-width: 729px;
  margin: 0 auto;
  position: relative;
}

.dropdown {
  display: inline;
  margin: 0 16px 0 0;
  font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
  .dropdown-toggle {
    &::after {
      border: none !important;
      content: '\e91e';
      font-family: 'icomoon';
      font-size: 0.889rem;
      margin-left: 6px;
      vertical-align: middle;
    }

    &.btn {
      border-radius: 24.5px;
      font-size: 0.889rem;
      padding: 2px 8px;

      &:focus-within {
        outline: 2px solid blue;
      }
    }

    &.btn-white {
      color: var(--primary);
    }

    &.btn-gray-light {
      color: var(--gray-100);
      background-color: var(--gray-light);
      &:hover {
        background-color: var(--orange);
        border-color: var(--orange);
        color: var(--dark-contrast-color);
      }
    }
  }

  .dropdown-menu {
    max-width: 145px;
    height: auto;
    padding: 0 11px;
    border-radius: 16px;
    border: solid 1px var(--gray-100);
    background-color: var(--white);

    .dropdown-item {
      padding: 14px 0;
      color: var(--gray-100);
      border-bottom: 1px solid var(--primary);
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      line-height: 1.38;
      letter-spacing: 0.15px;

      &:hover {
        color: var(--primary);
        background: var(--white);
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

.nav-pills {
  .nav-link {
    border-radius: 0;
    padding: 0;
  }
}

.navbar-light {
  .navbar-nav {
    .nav-link {
      button {
        height: 36px;
        color: var(--white);
        padding: 5px 12px;
        border-radius: 22px;
        border: solid 1.5px var(--transparent);
        background: var(--transparent);
        display: flex;
        align-items: center;
      }

      &.active,
      &:hover {
        button {
          color: var(--success);
          border: solid 1.5px var(--success);
        }
      }
    }
  }
}

.box-shadow {
  box-shadow: 0 8px 10px 0 #00000019;
}

.out-container {
  position: relative;
  top: 50px;
  z-index: 3;
}

.w-75 {
  width: 100% !important;
  @media (min-width: 768px) {
    width: 75% !important;
  }
}

.slick-slider {
  margin-bottom: 10px;
}

.slick-arrow {
  z-index: 2;
}

.padding-slider {
  margin: 0 -15px; // margem negativa para compensar o padding dos itens

  .slick-slide {
    padding: 0 15px;
  }
}

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

.uppercased {
  text-transform: uppercase;
}

.sb-show-main.sb-main-padded {
  margin: 0px;
  padding: 0px;
}

.spinner-border {
  margin: auto;
}

.loading-page {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1000;

  &__spinner {
    left: 50%;
    top: 50%;
    position: absolute;
    z-index: 4;
  }
}

// Chatbot Lucia e ícone de cookies

div#ot-sdk-btn-floating.ot-floating-button {
  bottom: 6px;
  height: 30px;
  width: 30px;

  @media screen and (min-width: $grid-sm) {
    height: 50px;
    width: 50px;
  }
}

#widget-open.chatsoftinova.tc-widget.widget-type.circular-widget,
#agent2.chatsoftinova.agent.circle {
  bottom: 6px;
  width: 50px;
  height: 50px;

  @media screen and (min-width: $grid-md) {
    bottom: 50px;
    width: 80px;
    height: 80px;
  }
}

.chatsoftinova #speech-bubble-link {
  display: none;

  @media screen and (min-width: $grid-md) {
    display: block;
  }
}
