// Arrows
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 48px;
  width: 48px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  color: var(--white);
  background: var(--primary);
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  border-radius: 50%;

  &:hover,
  &:focus {
    color: var(--white);
    background: var(--primary);
  }

  &.slick-disabled {
    display: none !important;
  }
}

.slick-prev {
  left: -15px;
  [dir='rtl'] & {
    left: auto;
    right: -14px;
  }

  @media screen and (min-width: 768px) {
    left: -35px;
    [dir='rtl'] & {
      left: auto;
      right: -34px;
    }
  }

  &:before {
    content: '\e91f';
    font-family: 'icomoon';
    font-size: 24px;
    color: var(--white);
    position: absolute;
    left: 12px;
    [dir='rtl'] & {
      content: '\e920';
    }
  }
}

.slick-next {
  right: -15px;
  [dir='rtl'] & {
    left: -15px;
    right: auto;
  }

  &:before {
    content: '\e920';
    font-family: 'icomoon';
    font-size: 24px;
    color: var(--white);
    position: absolute;
    right: 12px;
    [dir='rtl'] & {
      content: '\e91f';
    }
  }
}

.slick-arrow {
  z-index: 2;
}

// Slider

.slick-slide {
  padding: 0 4px;

  &.slick-active {
    display: block;
  }
}

.slick-track {
  padding: 4px 0;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

// Dots

.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 10px 0 0 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: unset;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      border-radius: 50%;
      background: transparent;
      display: block;
      height: 8px;
      width: 8px;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 0;
      cursor: pointer;

      &:before {
        content: none;
      }
    }
    &.slick-active button {
      width: 20px;
      border-radius: 4px;

      &:before {
        content: none;
      }
    }
  }

  // Theme

  &.primary {
    li {
      button {
        border: solid 1px var(--primary);
      }

      &.slick-active {
        button {
          border: solid 1px var(--primary);
          background: var(--primary);
        }
      }
    }
  }

  &.white {
    li {
      button {
        border: solid 1px var(--white);
      }

      &.slick-active {
        button {
          border: solid 1px var(--white);
          background: var(--white);
        }
      }
    }
  }

  &.success {
    li {
      button {
        border: solid 1px var(--success);
      }

      &.slick-active {
        button {
          border: solid 1px var(--success);
          background: var(--success);
        }
      }
    }
  }

  &.secondary {
    li {
      button {
        border: solid 1px var(--secondary);
      }

      &.slick-active {
        button {
          border: solid 1px var(--secondary);
          background: var(--secondary);
        }
      }
    }
  }
}
