.external-link-redirect {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 40px;
  text-align: center;

  &__image {
    margin-bottom: 32px;
  } // img

  &__text {
    margin-bottom: 50px;
  } // &__text

  .form-check-label {
    font-size: 0.875rem;
    color: var(--gray-100);
  }

  .form-check-input {
    margin-top: 8px;
  }

  @media screen and (min-width: 960px) {
    padding: 40px 55px;

    .form-check-label {
      font-size: 0.777rem;
    }
  } // @media screen and (min-width: 960px)
} // .external-link-redirect
