.Links {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__item {
    padding: 2px 0;
    font-family: "UnimedSans-2020-Regular", Arial, sans-serif;

    @media screen and (min-width: 768px) {
      padding: 0 25px 0 0;
    }
  }

  &__link{
    color: var(--white);
    font-size: 0.775rem;
    letter-spacing: 0.19px;
    white-space: nowrap;
    transition: color .2s ease;

    &:hover {
      color: var(--success);
    }
  }
}
