.PerfilBar {
  &.perfil-bar__wrapper {
    position: relative;
    overflow: hidden;
    &.client {
      background-color: var(--green-dark-200);
      .PerfilBar__profile,
      .PerfilBar__profile-wrapper {
        color: var(--white);
      }
    }
    &.clientepj {
      background-color: var(--secondary);
      .PerfilBar__profile,
      .PerfilBar__profile-wrapper {
        color: var(--white);
      }
    }
    &.sinapse,
    &.cooperado {
      background-color: var(--sucess);
      .perfil-bar__intro,
      .perfil-bar__title {
        color: var(--green-dark-200);
      }
    }
    &.secretaria {
      background-color: var(--purple);
      .PerfilBar__profile,
      .PerfilBar__profile-wrapper {
        color: var(--white);
      }
    }
    &.prestador,
    &.tasyprestador,
    &.webprestadorautorizado,
    &.mainmenu {
      background-color: #0c794e;
    }
    &.fornecedor {
      background-color: var(--primary);
    }
    &.simulador_de_acesso {
      background-color: #0c423c;
      .PerfilBar__profile,
      .PerfilBar__profile-wrapper {
        color: var(--white);
      }
    }
    &.auditor {
      background-color: var(--gray-100);
      .PerfilBar__profile,
      .PerfilBar__profile-wrapper {
        color: var(--white);
      }
    }
    &.webresponsavelfinpf {
      background-color: #8c4107;
      .PerfilBar__profile,
      .PerfilBar__profile-wrapper {
        color: var(--white);
      }
    }
    &.webresponsavelfinpj {
      background-color: #682d00;
      .PerfilBar__profile,
      .PerfilBar__profile-wrapper {
        color: var(--white);
      }
    }
    &.empresacobranca {
      background-color: #222222;
      .PerfilBar__profile,
      .PerfilBar__profile-wrapper {
        color: var(--white);
      }
    }
  }
  &__perfil-bar-detail {
    &.perfil-bar__detail {
      background: rgba(255, 255, 255, 0.05);
      position: absolute;
      height: 100%;
      width: 65%;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: 0 100px 0 0;
      background-color: var(--primary);

      &.client {
        background-color: #135e40;
      }
      &.clientepj {
        background-color: #5d2e82;
      }
      &.sinapse,
      &.cooperado {
        background-color: #c7dd72;
      }
      &.secretaria {
        background-color: #92207f;
      }
      &.prestador,
      &.tasyprestador {
        background-color: var(--primary);
      }
      &.fornecedor,
      &.mainmenu {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &.simulador_de_acesso {
        background-color: #1a4f49;
      }
      &.auditor {
        background-color: #63646d;
      }
      &.webresponsavelfinpf {
        background-color: #682d00;
      }
      &.webresponsavelfinpj {
        background-color: #8c4107;
      }
      &.empresacobranca {
        background-color: #575555;
      }
      @media screen and (max-width: 768px) {
        width: 80%;
      }
    }
  }
  &__perfil-bar-icon {
    &.perfil-bar__icon {
      width: 186px;
      height: 186px;
      position: absolute;
      top: -20px;
      left: 0;
      display: block;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  &__perfil-bar-intro {
    &.perfil-bar__intro {
      margin: 29px 0px 8px 27px;
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: var(--white);
      @media screen and (max-width: 768px) {
        margin: 0 0 5px 0;
        padding-top: 24px;
        font-size: 0.7rem;
      }
      &.perfil-bar__cod {
        margin: 29px 5px 8px 0px;
        font-family: 'UnimedSans-2020-BookCondensed', Arial, sans-serif;
        font-size: 0.8rem;
        white-space: nowrap;
        display: block;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 768px) {
          font-size: 0.7rem;
        }
      }
    }
  }
  &__perfil-bar-title {
    &.perfil-bar__title {
      margin: 8px 0 8px 27px;
      text-align: left;
      font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
      font-size: 1.3rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: var(--white);
      @media screen and (max-width: 768px) {
        font-size: 1rem;
        margin: 5px 0 0;
        padding-bottom: 24px;
        font-size: 1rem;
      }
    }
  }
  &__profile-wrapper {
    margin-bottom: 17px;
    @media screen and (min-width: 768px) {
      margin-left: 27px;
    }
  }
  &__profile {
    color: #821c71;

    &:hover {
      filter: brightness(0.6);
      color: #821c71;
    }
  }
}
