@import './../../../../../styles/utils';

.email-success {
  .content {
    .email-success-body {
      .email-success-area {
        .password-input {
          position: relative;
          margin-bottom: 23px;
          @media screen and (min-width: 768px) {
            margin-bottom: 30px;
          }

          #password {
            padding-left: 60px;
          }

          .password-example {
            position: absolute;
            top: 42px;
            left: 10px;
            font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
            font-size: remMobile(14px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: var(--gray-100);

            @media screen and (min-width: 768px) {
              font-size: remDesktop(14px);
              top: 45px;
            }

            &::after {
              content: '';
              border-right: 1px solid var(--gray-form);
              padding-left: 10px;
            }
          }

          .password-visible {
            position: absolute;
            top: 42px;
            right: 5px;
            cursor: pointer;

            @media screen and (min-width: 768px) {
              font-size: remDesktop(14px);
              top: 45px;
            }
          }
        }

        .submit-button {
          font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
          font-size: remMobile(16px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          justify-content: center;
          margin-bottom: 23px;

          @media screen and (min-width: 768px) {
            font-size: remDesktop(18px);
            line-height: 1.39;
            margin-bottom: 30px;
          }
        }
      }

      .forgot {
        text-align: center;
        a {
          font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
          font-size: remMobile(14px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: var(--secondary);

          @media screen and (min-width: 768px) {
            font-size: remDesktop(14px);
          }
        }
      }

      .success {
        .cta {
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: center;
          align-items: center;
          margin: 32px 0;

          @media screen and (min-width: 768px) {
            margin: 32px 0;
          }
        }

        .icon {
          margin: 16px auto 24px;
          display: block;
          width: 48px;
          height: 48px;
          fill: var(--purple);

          g,
          path {
            fill: var(--purple);

            path {
              fill: var(--purple);

              &:last-of-type {
                fill: var(--purple);
              }
            }
          }

          @media screen and (min-width: 768px) {
            margin: 24px auto 32px;
            width: 56px;
            height: 56px;
          }
        }

        .text {
          font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: 0.15px;
          text-align: center;
          color: var(--gray-100);

          @media screen and (min-width: 768px) {
            font-size: remDesktop(18px);
            letter-spacing: 0.25px;
          }
        }

        .mail {
          font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
          font-size: remMobile(16px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: 0.15px;
          text-align: center;
          margin: 24px auto;
          color: var(--purple);

          @media screen and (min-width: 768px) {
            font-size: remDesktop(18px);
            letter-spacing: 0.25px;
            margin: 32px auto;
          }
        }

        .resend-email {
          display: block;
          margin: 0 auto;
        }
      }
    }

    .email-success-footer {
      .divider {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        margin-bottom: 34px;

        @media screen and (min-width: 768px) {
          margin-bottom: 28px;
        }

        span {
          &.line {
            border-bottom: 1px solid var(--gray-400);
            margin: 0 auto;

            &.left {
              padding-left: 46%;
            }
            &.right {
              padding-right: 46%;
            }
          }
        }

        p {
          font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
          font-size: remMobile(16px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: 0.15px;
          text-align: center;
          color: var(--gray-100);

          @media screen and (min-width: 768px) {
            font-size: remDesktop(18px);
            line-height: 1;
            letter-spacing: 0.25px;
          }
        }
      }

      .social-login {
        .login {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: center;
          align-items: center;
          border: 1px solid var(--gray-100);
          border-radius: 21.5px;
          cursor: pointer;
          padding: 10px;
          margin-bottom: 8px;

          &.google {
          }

          &.facebook {
          }

          .text {
            font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
            font-size: remMobile(16px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: 0.15px;
            color: var(--gray-100);
            margin: 0 auto;

            @media screen and (min-width: 768px) {
              font-size: remDesktop(18px);
              letter-spacing: 0.25px;
              color: var(--gray-100);
            }
          }

          .icon {
            width: 16px;
            height: auto;

            @media screen and (min-width: 768px) {
              width: 24px;
            }
          }
        }
      }

      .register,
      .faq {
        margin: 10px 0;

        p {
          font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
          font-size: remMobile(14px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: center;
          color: var(--gray-100);

          @media screen and (min-width: 768px) {
            font-size: remDesktop(14px);
          }

          a {
            color: var(--primary);
          }
        }
      }
    }
  }
}
