@import './../../../../../styles/utils';

.login-email-validated {
  .profile {
    &__line {
      width: 100%;
      min-height: 135px;
      font-size: 16px;

      @media screen and (min-width: 768px) {
        min-height: 88px;
      }
    }

    &__add-profile {
      width: 100%;
    }

    &__page-steps {
      .page-steps__simple-big-buttons {
        max-width: 417px;
      }

      .simple-big-button {
        min-height: 121px;
      }
    }

    &__username {
      font-size: 16px;
    }

    &__contract {
      word-break: break-all;
      font-size: 0.8rem;
      font-family: 'UnimedSans-2020-BookCondensed', Arial, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__invited {
      .shared-profile {
        font-size: 0.7em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .btn {
        word-break: normal;
      }
    }

    .card.card-primary.outline:hover .profile__remove-invited,
    .card.card-primary.outline:hover .profile__remove-invited > i {
      color: black !important;
    }
  }

  .content {
    .login-email-validated-body {
      padding: 24px 0;

      .profile__options {
        max-height: 31px;
        padding: 4px 16px !important;
      }

      @media screen and (min-width: 768px) {
        padding: 32px 0;
      }

      .container {
        width: initial;
        padding: 0 1rem;

        .profile {
          border-radius: 16px;
        }

        .add-profile {
          position: relative;
          padding: 12px;
          background: none;
          color: var(--purple);
          border: 2px dashed var(--purple);
          border-radius: 16px;
        }

        .text {
          font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
          font-size: remMobile(16px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: 0.15px;
          text-align: center;
          color: var(--gray-100);

          @media screen and (min-width: 768px) {
            font-size: remDesktop(18px);
            letter-spacing: 0.25px;
          }
        }

        .dropdown {
          display: contents;
          white-space: nowrap;
          max-width: max-content;
          .dropdown-toggle.btn {
            @media (max-width: 600px) {
              font-size: 0.7rem;
            }
          }
        }

        .dropdown-item {
          color: var(--primary) !important;
        }
      }
    }

    .submit-button {
      width: max-content;
      margin: 0 auto;
    }
  }
  .form-check-label {
    a {
      color: var(--egg-shell);
    }
  }
  .not-activated-message {
    color: var(--white) !important;
  }
}
