.Breadcrumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 0.667rem;
  font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
  line-height: 1.5;

  @media screen and (max-width: 768px) {
    max-width: 120px;
  }

  a {
    color: var(--gray-form);
  }

  &::after {
    content: '\e920';
    font-family: 'icomoon';
    font-size: 0.667rem;
    margin: auto 2.5px;
    color: var(--gray-form);
    position: relative;
    top: 2px;
  }

  &:last-child {
    a {
      color: var(--primary);
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
    }

    &::after {
      content: '';
    }
  }

  &.bread-primary {
    display: inline;
    font-size: 0.667rem;
    font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
    line-height: 1.14;

    a {
      color: var(--gray-form);
    }

    &::after {
      content: '\e920';
      font-family: 'icomoon';
      font-size: 0.667rem;
      margin: auto 2.5px;
      color: var(--gray-form);
      position: relative;
      top: 2px;
    }

    &:last-child {
      a {
        color: var(--primary);
        font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      }

      &::after {
        content: '';
      }
    }

    &-background {
      a {
        color: var(--white);
      }

      &::after {
        color: var(--white);
      }

      &:last-child {
        a {
          color: var(--white);
          font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
        }
      }
    }
  }

  &.bread-light {
    display: inline;
    font-size: 0.667rem;
    font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
    line-height: 1.14;

    a {
      color: var(--white);
    }

    &::after {
      content: '\e920';
      font-family: 'icomoon';
      font-size: 0.667rem;
      margin: auto 2.5px;
      color: var(--gray-light);
      position: relative;
      top: 2px;
    }

    &:last-child {
      a {
        color: var(--white);
        font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      }

      &::after {
        content: '';
      }
    }
  }

  &.bread-gray-100 {
    display: inline;
    font-size: 0.667rem;
    font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
    line-height: 1.14;

    a {
      color: var(--gray-100);
    }

    &::after {
      content: '\e920';
      font-family: 'icomoon';
      font-size: 0.667rem;
      margin: auto 2.5px;
      color: var(--gray-100);
      position: relative;
      top: 2px;
    }

    &:last-child {
      a {
        color: var(--primary);
        font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      }

      &::after {
        content: '';
      }
    }
  }

  &.bread-tertiary {
    &-background {
      a {
        color: var(--white);
      }

      &::after {
        color: var(--white);
      }

      &:last-child {
        a {
          color: var(--white);
          font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
        }
      }
    }
  }
}
