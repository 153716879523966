.Accessibility {
  &.accessibility-menu {
    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (min-width: 992px) {
        display: inline;
      }
    }
    p,
    span {
      font-size: 0.775rem;
      color: var(--white);
      display: inline;
      font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
    }

    button {
      font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
      border: none;
      background: transparent;
      color: var(--white);
      font-size: 1rem;
      padding: 0 10px;
      display: inline-flex;
      align-items: center;

      &:hover {
        color: var(--success);
      }
    }
  }
}
