.FormSearchContainer {
  .overlay {
    position: fixed;
  }

  &.float {
    position: relative;
    min-height: 60px;

    .FormSearch {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      z-index: 1;
      transform: translate(-50%, 0);
    }
  }
}

.FormSearch {
  position: relative;
  border-radius: 30px;
  border: solid 1.5px var(--white);
  box-shadow: 0 0 1.5px 0 white;
  max-width: 680px;
  margin: 0 auto;
  overflow: hidden;
  transition: background-color 0.2s ease;

  &:focus-within {
    outline: 2px solid orange;
  }

  input {
    &::placeholder,
    &::-webkit-input-placeholder,
    &:-moz-placeholder {
      color: var(--white);
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
    }
  }

  &__input {
    background: transparent;
    padding: 18px 18px 17px;
    margin: 0 auto;
    width: 100%;
    height: 48px;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--white);

    @media screen and (min-width: 512px) {
      height: 60px;
    }

    &:focus-visible {
      outline: 1px solid orange;
    }

    &:active,
    &:focus {
      background: var(--primary);
      color: var(--white) !important;
    }

    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: var(--white);
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: var(--white);
      opacity: 1;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: var(--white);
      opacity: 1;
    }
    &::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--white);
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--white);
    }
    &::placeholder {
      /* Most modern browsers support this now. */
      color: var(--white);
    }
  }

  &__submit {
    border: none;
    background: transparent;
    position: absolute;
    top: 12px;
    right: 1em;

    i::before {
      font-size: 1.2rem;
      color: var(--white);
    }

    @media screen and (min-width: 512px) {
      top: 17px;
    }
  }

  // Listagem de resultados
  .FormSearch__resultList {
    // Usa a classe completa para sobrescrever o estilo de padding do .navbar .nav ul
    padding: 0 18px;
  }

  &__result {
    position: relative;
    padding: 15px 30px 15px 0;
    border-top: solid 1.5px var(--white);
    color: var(--white);
    list-style: none;

    i::before {
      font-size: 1.2rem;
      color: var(--white);
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translate(0, -50%);
    }

    &-link,
    &-link:hover {
      color: var(--white);
    }
  }
}
