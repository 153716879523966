@import './../../../styles/utils';

.section-title {
  .title {
    font-family: "UnimedSans-2020-BookCondensed", Arial, sans-serif;
    text-transform: uppercase;
    font-size: remMobile(14px);
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-100);
    
    @media screen and (min-width: 768px) {
      font-size: remDesktop(16px);
      line-height: 1.25;
      letter-spacing: 0.18px;
    }
  }

  .low-bar {
    height: 4px;
    width: 120px;
    background-color: var(--gray-100);
  }
}
