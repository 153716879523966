@import "./../../../styles/utils";

.BigButton {
  text-align: left;
  width: 100%;

  .card-icon {
    display: inline-flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 12px;
    height: 100%;
    width: auto;
    min-width: 130px;
    max-width: 170px;
    max-height: 120px;
    margin: 0 16px 0 0;
    transition: all 0.2s ease;
    place-content: unset;
    align-items: unset;

    @media screen and (min-width: 512px) {
      padding: 16px;
      min-height: 160px;
      max-height: 160px;
      min-width: 160px;
      max-width: 250px;
    }

    &.full-width {
      width: 100%;
    }

    .card-icon-img {
      width: 24px;
      height: 24px;
      @media screen and (min-width: 512px) {
        width: 30px;
        height: 30px;
      }
    }

    p {
      font-family: "UnimedSans-2020-SemiBold", Arial, sans-serif;
      font-size: 1rem;
      margin-top: 4px;
      margin-bottom: 4px;
      min-height: 44px;
      text-transform: uppercase;

      @media screen and (min-width: 512px) {
        margin-top: 16px;
        margin-bottom: 0;
        min-height: unset;
      }
    }

    .card-icon-text {
      font-family: "UnimedSans-2020-regular", Arial, sans-serif;
      font-size: remMobile(16px);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;

      @media screen and (min-width: 768px) {
        font-size: remDesktop(18px);
        line-height: 1.39;
      }
    }

    a,
    i {
      display: block;
      margin-top: auto;
      height: 20px;
      font-size: unset;
      color: unset;
    }
  }
}
