.SocialMedia {
  max-width: 160px;

  &__title {
    font-family: "UnimedSans-2020-Book", Arial, sans-serif;
    font-size: 1.125rem;
  }

  @media screen and (min-width: 768px) {
    margin-left: auto;

    &__title {
      font-size: 1.333rem;
    }
  }

  &__icons-wrapper {
    &.icons-wrapper {
      margin-left: auto;
      max-width: 160px;
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      align-self: space-between;

      .icone-facebook::before,
      .icone-instagram::before,
      .icone-youtube::before,
      .icone-linkedin::before {
        font-size: 1.3rem;
      }
      a,
      i {
        color: var(--white);
        &:hover {
          color: var(--success);
        }
        font-size: 1.3rem;
      }
      svg {
        fill: var(--white);
        max-width: 2rem;
        max-height: 2rem;

        path {
          fill: var(--white);

          &:last-of-type {
            fill: var(--white);
          }
        }
      }
    }
  }
}
