.custom-button {
  width: auto;

  &.btn-primary {
    fill: white;
  }
  &.btn-outline-primary {
    fill: var(--primary);
    &:hover {
      fill: white;
    }
  }
  &.btn-secondary {
    fill: white;
  }
  &.btn-outline-secondary {
    fill: var(--secondary);
    &:hover {
      fill: white;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}

.btn {
  word-break: break-all;
}
