.navbar {
  width: 100%;
  padding: 23px 0 !important;

  @media screen and (min-width: 992px) {
    border-bottom: 0.5px solid #ddd;
    .container {
      padding: 0;    
    }  
  }

  &.navbar--logged nav ul li > button span {
    font-size: 1rem;

    @media screen and (min-width: 992px) {
      font-size: 0.888rem;
    }
  }

  
  nav ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;

    li .navbar-login {
      @media screen and (min-width: 992px) {
        margin-right: 5px;

        &.no-text {
          padding: 11px 12px 11px 12px;
        }
      }
    }
  }

  &-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    @media screen and (min-width: 992px) {
      justify-content: space-between;
    }

    li:focus-visible,
    a:focus-visible,
    img:focus-visible,
    button:focus-visible {
      color: var(--success);
      border: 1.5px solid var(--success);
      outline: 1px solid var(--success);
    }
  }

  &-img {
    max-width: 112px;
    height: auto;

    @media screen and (min-width: 992px) {
      max-width: 160px;

      .navbar--logged & {
        max-width: 124px;
        margin-right: 14px;
      }
    }
  }

  &-links {
    display: none;

    @media screen and (min-width: 992px) {
      display: inline;
      border: 1.5px solid var(--transparent);
      background: none;
      color: var(--white);
      font-family: 'UnimedSans-2020-SemiBold', Arial, sans-serif;
      transition: all 200ms ease;
      // height: 40px;
      border-radius: 22px;
      font-size: 0.889rem;
      line-height: 1.5;
      padding: 0.15rem 0.4rem;
    }
    @media screen and (max-width: 1199px) {
      font-size: 0.689rem;
      padding: 0.15rem 0.3rem;
    }

    &:hover,
    &.active {
      border-radius: 22px;
      border: 1.5px solid var(--success);
      color: var(--success);
    }

    &.active {
      + .navbar-overlay {
        display: block;
        + .navbar-dropdown {
          @media screen and (min-width: 992px) {
            display: grid;
            grid-template-columns: repeat(4, 290px);
            justify-content: center;

            .navbar--logged & {
              display: block;
            }
          }
        }
      }
    }
  }

  &-overlay {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 159px;
    left: 0;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &-search-container {
    flex: 1;
    margin: 40px 0;

    .FormSearch {
      max-width: 680px;
      margin: 0 auto;
    }
  }

  &-dropdown {
    display: none;
    position: absolute;
    top: 106px;
    left: 0;
    z-index: 4;

    @media screen and (min-width: 992px) {
      width: 100%;
      height: auto;
      background-color: var(--primary);

      .navbar--logged & {
        background-color: var(--green-dark-500);
        height: 530px;
        top: 94px;

        &.navbar-dropdown--search {
          height: auto;
        } // .navbar-search &
      } // .navbar--logged &
    } // @media screen and (min-width: 992px)

    .navbar--logged & {
      .container {
        height: 100%;
      } // .container

      .row {
        width: 100%;
        height: 100%;
      } // .row

      &__main,
      &__second-level {
        padding: 37px 0;
        height: 100%;
      } // &__main, &__second-level

      &__main {
        position: relative;
        background-color: var(--green-dark-100);
      } // &__main

      &__second-level {
        .navbar-dropdown-container {
          display: flex;
          flex-flow: wrap column;
          max-height: 450px;

          .navbar-dropdown-list-two {
            max-height: none;
          } // .navbar-dropdown-list-two
        } // .navbar-dropdown-container
      } // &__second-level

      &__main-bg {
        position: absolute;
        top: 0;
        right: 100%;
        width: 1000px;
        height: 100%;
        background-color: var(--green-dark-100);
        z-index: 3;
      } // &__main-bg
    } // .navbar--logged &

    &-info {
      padding: 24px 16px;
      height: max-content;

      img {
        width: 258px;
        // max-height: 125px;
        margin-bottom: 8px;
      }

      h5,
      p {
        font-size: 1rem;
        margin: 0 8px;
      }

      h5 {
        font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
      }

      p {
        font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
        line-height: 1.4;
      }
    }

    &-container {
      &:nth-child(3) {
        background: #007c44;
      }
      &:nth-child(4) {
        background: #02703e;
      }

      .navbar--logged & {
        &:nth-child(3) {
          background: transparent;
        } // &:nth-child(3)

        &:nth-child(4) {
          background: transparent;
        } // &:nth-child(4)
      } //.navbar--logged &
    } // &-container

    &-list {
      &-one {
        max-width: 290px;
        min-height: 50px;
        max-height: 66px;

        &:hover,
        &.active {
          background-color: #007c44;
          i {
            visibility: visible;
          }
        }

        &:hover {
          color: var(--white);
        }

        &.active {
          button,
          i {
            color: var(--success);
          }
        }

        &-btn {
          border: none;
          background: var(--transparent);
          border-bottom: 1px solid var(--green-dark-100);
          width: 100%;
          min-height: 50px;
          max-height: 66px;
          padding: 6px 8px;
          text-align: left;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--white);

          i {
            visibility: hidden;
          }

          &:hover,
          &.active {
            background-color: var(--primary);
            i {
              visibility: visible;
            }
          }

          &:hover {
            color: var(--white);
          }

          &.active {
            color: var(--success);
          }
        } // &-btn

        .navbar--logged & {
          max-width: none;
          &-btn {
            border-bottom: 1px solid var(--primary);

            i {
              visibility: visible;
            } // i

            span {
              font-family: 'UnimedSans-2020-Bold', Arial, sans-serif;
              font-size: 1rem;
              color: var(--yellow-light);
              text-transform: uppercase;
            } // span

            &:hover {
              background-color: transparent;
            } // &:hover
          } // &-btn

          &:hover,
          &.active {
            background-color: transparent;

            .navbar-dropdown-list-one-btn {
              span {
                color: var(--success);
              } // span

              i {
                color: var(--success);
              } // i
            } // .navbar-dropdown-list-one-btn
          } // &:hover, &.active
        } // .navbar--logged &
      } // &-one

      &-two {
        max-width: 290px;
        background-color: #007c44;
        min-height: 50px;
        max-height: 66px;

        &:hover {
          color: var(--white);
        }

        &.active {
          .navbar-dropdown-list-two-btn {
            color: var(--success);
          }
        }

        &:hover,
        &.active {
          background: #02703e;

          i {
            display: inline;
          }
        }

        &-btn {
          border: none;
          border-bottom: 1px solid var(--green-dark-100);
          background: var(--transparent);
          margin: 0 auto;
          min-height: 50px;
          max-height: 67px;
          max-width: 290px;
          padding: 6px 8px;
          text-align: left;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--white);

          @media screen and (min-width: 992px) {
            width: 100%;
            margin: initial;
          }

          span {
            font-size: 1rem;
          }

          i {
            display: none;
          }

          &:hover {
            color: var(--white);
          }
        }

        .navbar--logged & {
          background-color: transparent;

          .navbar-list-items {
            span {
              font-family: 'UnimedSans-2020-Bold', Arial, sans-serif;
              color: var(--egg-shell);
            } // span

            i {
              display: block;
              color: var(--egg-shell);
            } // i
          } // .navbar-list-items

          &-btn {
            background-color: transparent;
            border-bottom: none;
          } // &-btn
        } //.navbar--logged &
      }

      &-three {
        max-width: 290px;
        background-color: #02703e;
        min-height: 50px;
        max-height: 66px;

        &:hover {
          background: #006c41;
          color: var(--white);

          i {
            display: inline;
          }
        }

        &-btn {
          border: none;
          border-bottom: 1px solid var(--green-dark-100);
          background: var(--transparent);
          width: calc(100% - 16px);
          margin: 0 auto;
          min-height: 50px;
          max-height: 66px;
          padding: 6px 8px;
          text-align: left;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--white);

          i {
            display: none;
          }

          &:hover {
            background: #006c41;
            color: var(--white);

            i {
              display: inline;
            }
          }
        }

        .navbar--logged & {
          background-color: transparent;
        } // .navbar--logged &
      } // &-three
    }
  }

  &-login {
    color: var(--white);
    background: var(--transparent);
    border-radius: 20px;
    border: none;
    display: flex;
    align-items: center;
    transition: background 200ms ease;
    padding: 5px;

    @media screen and (min-width: 992px) {
      height: 40px;
      padding: 7px 15px 8px 8px;
      background: var(--primary);
    }

    &:hover {
      @media screen and (min-width: 992px) {
        color: var(--white);
        background: #006c41;
      }
    }

    &.active {
      color: var(--white);
      background: var(--primary);
    }

    i.icone-person::before,
    i.icone-close-book::before {
      font-size: 2rem;
      @media screen and (min-width: 992px) {
        font-size: 1rem;
      }
    }

    span {
      display: none;
      @media screen and (min-width: 992px) {
        display: inline;
        line-height: 1.56;
        letter-spacing: 0.22px;
        margin-left: 0.25rem;
      }
    }

    .navbar--logged & {
      position: relative;
      z-index: 3;
      margin-right: 20px;
      padding: 16px;
      background-color: var(--white);
      border-radius: 20px;

      span,
      i {
        color: var(--primary);
      } // span, i

      i:before {
        font-size: 1.3rem;
      } // &:before

      @media screen and (min-width: 992px) {
        margin-left: 26px;
      }
    } // .navbar--logged &

    &.navbar-login--mobile {
      padding: 8px;

      i {
        color: var(--primary);

        &:before {
          font-size: 1.4rem;
        } // &:before
      } // i
    }
  } // &-login

  &-search {
    display: none;
    @media screen and (min-width: 992px) {
      display: flex;
      align-items: center;
      // margin-right: 8px;
      height: 40px;
      border: none;
      color: var(--white);
      background: var(--transparent);

      i.icone-search::before {
        font-size: 1.333rem;
      }

      &:hover {
        color: rgba(255, 255, 255, 0.9);
      }
    }

    &.active {
      + .navbar-overlay {
        display: block;
        + .navbar-dropdown {
          @media screen and (min-width: 768px) {
            display: grid;
          }
        }
      }
    }
  }

  &-menu {
    display: inline;
    height: 40px;
    border: none;
    color: var(--white);
    background: var(--transparent);

    .navbar--logged & {
      height: 32px;
    } // .navbar--logged &

    i.icone-menu::before {
      font-size: 2rem;
    }

    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }
    @media screen and (min-width: 992px) {
      display: none;
    }

    &-mobile {
      padding: 0 16px;
      display: none;
      width: 100%;
      height: 100vh;
      background: var(--primary);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 4;
      margin: 0 0 20px;
      &.active {
        display: block;
      }

      .navbar--logged & {
        background: var(--green-dark-100);

        .navbar-menu-link {
          span {
            font-size: 1rem;
          } // span
        } // .navbar-menu-link
      } // .navbar--logged &

      .navbar-menu {
        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 32px 0 24px;

          h6 {
            font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
            font-size: 1.125rem;
            color: var(--white);
          }
          button {
            border: none;
            background-color: var(--transparent);
            padding: 0 0 8px;
            .icone-close-book::before {
              color: var(--white);
              font-size: 1.8rem;
            }
            .icone-arrow-left::before {
              color: var(--white);
              font-size: 1.8rem;
            }
          }
        }

        &-container {
          padding: 0 16px;
          display: none;
          width: 100%;
          height: 100vh;
          background: var(--primary);
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 4;

          .navbar--logged & {
            background: var(--green-dark-100);
          }

          ul {
            li {
              margin: 0 auto;
            }
          }

          &.active {
            display: unset;
            overflow-y: scroll;
          }
        }

        &-link {
          border: none;
          border-bottom: 1px solid var(--green-dark-100);
          background: var(--transparent);
          width: 100%;
          margin: 0 auto;
          min-height: 44px;
          max-height: 66px;
          padding: 6px 8px;
          text-align: left;
          color: var(--white);
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-drop {
          border: none;
          border-bottom: 1px solid var(--green-dark-100);
          background: var(--transparent);
          width: 100%;
          margin: 0 auto;
          min-height: 44px;
          height: 100%;

          .navbar--logged & {
            margin-bottom: 40px;

            summary {
              border-bottom: 1px solid var(--egg-shell);
              font-family: 'UnimedSans-2020-SemiBold', Arial, sans-serif;
              font-size: 1rem;
              text-transform: uppercase;
              color: var(--egg-shell);
            }

            &[open] {
              summary {
                border-bottom: 1px solid var(--egg-shell);
                color: var(--egg-shell);
              } // summary

              .navbar-menu-link {
                margin: 0;
              } // .navbar-menu-link

              .navbar-menu-drop {
                width: 100%;
                margin: 0;

                summary {
                  border-bottom: none;
                  color: var(--egg-shell);
                  text-transform: none;
                } // summary

                &[open] {
                  summary {
                    color: var(--egg-shell);
                  } // summary

                  .navbar-menu-link {
                    margin: 0;
                  } // .navbar-menu-link
                } // &[open]
              } // .navbar-menu-drop
            } // &[open]
          } // .navbar--logged &

          &[open] {
            border-bottom: none;
            summary {
              border-bottom: 1px solid var(--green-dark-100);
              color: var(--success);

              &::after {
                content: '\e91e';
                font-family: 'icomoon';
                float: right;
                margin-right: 8px;
                transform: rotate(180deg);
                font-size: 1rem;
              }
            }

            .navbar-menu-link {
              width: calc(100% - 16px);
              margin: 0 0 0 auto;
            }

            li {
              width: 100%;
              margin: 0 0 0 auto;
              details {
                border: none;
                background: transparent;
                &.navbar-menu-drop {
                  width: calc(100% - 16px);
                  margin: 0 0 0 auto;

                  &[open] {
                    summary {
                      color: var(--success);
                      &::after {
                        content: '\e91e';
                        font-family: 'icomoon';
                        float: right;
                        margin-right: 8px;
                        transform: rotate(180deg);
                        font-size: 1rem;
                      }
                    }
                    .navbar-menu-link {
                      width: calc(100% - 16px);
                      margin: 0 0 0 auto;
                    }
                  }

                  summary {
                    color: var(--white);
                    &::after {
                      content: '\e91e';
                      font-family: 'icomoon';
                      float: right;
                      margin-right: 8px;
                      transition: transform 200ms ease;
                      font-size: 1rem;
                      transform: rotate(360deg);
                    }

                    .navbar-menu-link {
                      width: calc(100% - 16px);
                      margin: 0 0 0 auto;
                    }
                  }
                }
              }
            }
          }

          summary {
            font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
            line-height: 1.4;
            font-size: 0.9rem;
            letter-spacing: 0.25px;
            color: var(--white);
            padding: 12px 0 12px 8px;

            &::marker {
              display: none;
            }

            &::after {
              content: '\e91e';
              font-family: 'icomoon';
              float: right;
              margin-right: 8px;
              transition: transform 200ms ease;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  .navbar-link__title {
    margin-top: 10px;
    color: var(--egg-shell);
  }
}
