.hero-headline {
  font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
  font-size: 1.625rem;
  line-height: 1.38;
  text-align: center;
  &.small {
    font-size: 0.75rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 2.333rem;
    line-height: 1.38;
    &.small {
      font-size: 1.625rem;
    }
  }
}
.hero-category {
  text-align: right;
  margin-top: 20px;
  .low-bar {
    float: right;
  }
  p {
    clear: both;
  }
}
