.simple-big-button {
  &__icon {
    height: 16px;
    width: 16px;

    &:before {
      font-size: 16px !important;
    }

    @media screen and (min-width: 512px) {
      height: 24px;
      width: 24px;

      &:before {
        font-size: 24px !important;
      }
    }
  }

  &__title {
    font-family: "UnimedSans-2020-SemiBold", Arial, sans-serif;
  }
}
