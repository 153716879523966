.forgot-access {
  .retrieve-password,
  .retrieve-email {
    overflow: hidden;

    &-enter {
      height: 0;
    }

    &-enter-active {
      height: 150px;
      transition: height 0.5s ease;
    }

    &-exit {
      height: 150px;
    }

    &-exit-active {
      height: 0;
      transition: height 0.5ms ease;
    }

    &__submit {
      justify-content: center;
      width: 100%;
    }
  }
}
