.custom-modal {
  width: 100%;
  height: 0px;
  visibility: hidden;
  display: none;
  pointer-events: none;
  opacity: 0;
  transition: visibility 0.2s ease, opacity 0.2s ease;

  @media screen and (max-width: 786px) {
    &.open.minimizeMobile {
      justify-content: center;
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);

      .custom-modal__container {
        padding-bottom: 0;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        border-radius: 1rem;
        width: 80%;
        height: max-content;
      }
    }
  }

  &.open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    visibility: visible;
    display: initial;
    pointer-events: all;
    opacity: 1;
    z-index: 4;

    .overlay {
      display: none;
    } // .overlay

    @media screen and (min-width: 960px) {
      .overlay {
        display: block;
      } // .overlay
    } // @media screen and (min-width: 960px)
  } // .open

  &__container {
    height: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;

    &.with-footer {
      padding-bottom: 98px;
    }

    @media screen and (min-width: 960px) {
      height: max-content;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: var(--white);
      transform: translate(-50%, -50%);
      border-radius: 16px;
      z-index: 5;

      .sm & {
        width: 500px;
        height: 500px;
      } // .sm &

      .md & {
        width: 650px;
        height: 650px;
      } // .md &

      .lg & {
        width: 800px;
      } // .lg &
    }
  }

  &__content {
    height: 100%;
    overflow-y: auto;
    @media screen and (min-width: 960px) {
      max-height: calc(100vh - 100px);
    }
    max-height: calc(100vh - 85px);
  }

  &__arrow-close,
  &__close {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
  }

  &__arrow-close {
    // float: left;
    flex: 1.2;
    justify-content: flex-start;
    display: flex;

    i {
      color: var(--primary);

      &:before {
        font-size: 1.5rem;
      } // :before
    } // i

    @media screen and (min-width: 960px) {
      display: none;
    }
  }

  &__close {
    display: none;

    &--display {
      display: flex;
      align-items: center;
    }

    @media screen and (min-width: 960px) {
      display: block;
    }
  }

  &__header {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (min-width: 960px) {
      justify-content: flex-end;
    }

    &--title {
      border-bottom: 1px solid var(--gray-light);
      margin-bottom: 1rem;
    }
  }

  &__icon {
    color: var(--gray-100);
  }

  .center &__title {
    @media screen and (min-width: 960px) {
      justify-content: center;
      text-align: center;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: middle;
    padding: 24px 15px;
    border-top: 1px solid var(--gray-light);
    background-color: var(--white);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    .center & {
      justify-content: center;
    } // &.center
  } // &__footer

  &__title {
    display: inline-block;
    margin-left: -15px;
    font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 1.38;
    flex: auto;

    @media screen and (min-width: 960px) {
      margin-left: 0;

      .center & {
        margin-left: 15px;
      } // &.center
    } // @media screen and (min-width: 960px)
  } // &__title

  &__footer-button {
    margin-left: 16px;
  } // &__footer-button

  &__footer-close {
    i {
      display: none;
    } // i
  } // &__footer-close
} // .custom-modal
