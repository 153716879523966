#root {
  .form-control {
    height: auto;
    border: 1px solid var(--primary);

    &:disabled {
      background: var(--gray-300);
      border: 1px solid var(--gray-000);
      pointer-events: none;
    } // &:disabled
  } // .form-control

  .was-validated {
    .form-control {
      &:invalid,
      &.is-invalid {
        border: 1px solid var(--danger);
        background-image: none;
        color: var(--danger);
      }

      &:valid,
      &.is-valid {
        background-image: none;
      } // &:valid, &.is-valid
    } // .form-control
  } // .was-validated

  .form-label {
    color: $gray-100;
    font-size: 1rem;
    line-height: 22px;
  }
} // #root
