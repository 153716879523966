@use 'sass:map';
@import 'styles/sizes';

.fast-access {
  &__item {
    position: absolute;
    z-index: -1;
    border-radius: 3px;

    &:focus-visible {
      color: var(--success);
      text-decoration: underline;
      z-index: 1;
      background-color: black;
    }

    @media screen and (min-width: $grid-xl) {
      position: initial;
    }
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;

    a,
    button {
      display: inline-block;
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      font-size: 0.775rem;
      color: var(--white);

      &:hover {
        color: var(--success);
      }
    }

    button {
      border: none;
      background-color: var(--transparent);
      margin-right: 20px;

      @media screen and (min-width: $grid-md) {
        margin-right: 25px;
      }
    }
  }

  p {
    font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
    display: inline;
    font-size: 0.775rem;
    margin-right: 20px;
    color: var(--white);
    white-space: nowrap;
  }
}
