@import './../../../styles/utils';

.login-page {
  .box {
    border: 1px solid var(--gray-form);
    border-radius: 16px;

    @media screen and (min-width: 768px) {
      max-width: 600px;
      margin: 0 auto;
    }

    &--error {
      border-color: var(--danger);
    }

    .container {
      width: 260px;
      padding: 0;

      @media screen and (min-width: 768px) {
        width: 350px;
        padding: initial;
      }
    }
    .login-header {
      padding: 24px 0;
      border-bottom: 1px solid var(--gray-400);

      @media screen and (min-width: 768px) {
        padding: 32px 0;
      }

      .title {
        font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
        font-size: remMobile(18px);
        line-height: 25px;
        letter-spacing: 0;
        text-align: center;
        color: var(--primary);

        @media screen and (min-width: 768px) {
          font-size: remDesktop(24px);
          line-height: 33px;
        }
      }
    }
  }

  .logo {
    width: 160px;
    height: auto;
    margin: 40px auto;

    @media screen and (min-width: 768px) {
      margin: 72px auto 40px;
    }
  }

  .navbar {
    background-color: var(--primary);

    .navbar-items li:last-of-type {
      padding-left: 28px;
    }

    .navbar-login {
      background-color: var(--white);
      color: var(--primary);

      &.navbar-login--mobile {
        background-color: initial;
        color: initial;

        i {
          color: var(--white);
        }
      }

      span {
        display: inline;
      }
    }
  }

  .login-header {
    padding: 24px 0;
    border-bottom: 1px solid var(--gray-400);

    @media screen and (min-width: 768px) {
      padding: 32px 0;
    }

    .title {
      font-family: 'UnimedSlab-2020-Bold', Arial, sans-serif;
      font-size: remMobile(18px);
      line-height: 25px;
      letter-spacing: 0;
      text-align: center;
      color: var(--primary);

      @media screen and (min-width: 768px) {
        font-size: remDesktop(24px);
        line-height: 33px;
      }
    }
  }

  .login-footer {
    .divider {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
      margin-bottom: 34px;

      @media screen and (min-width: 768px) {
        margin-bottom: 28px;
      }

      span {
        &.line {
          border-bottom: 1px solid var(--gray-400);
          margin: 0 auto;

          &.left {
            padding-left: 46%;
          }
          &.right {
            padding-right: 46%;
          }
        }
      }

      p {
        font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
        font-size: remMobile(16px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0.15px;
        text-align: center;
        color: var(--gray-100);

        @media screen and (min-width: 768px) {
          font-size: remDesktop(18px);
          line-height: 1;
          letter-spacing: 0.25px;
        }
      }
    }

    .social-login {
      .login {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        border: 1px solid var(--gray-100);
        border-radius: 21.5px;
        cursor: pointer;
        padding: 10px;
        margin-bottom: 8px;

        &.google {
        }

        &.facebook {
        }

        .text {
          font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
          font-size: remMobile(16px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: 0.15px;
          color: var(--gray-100);
          margin: 0 auto;

          @media screen and (min-width: 768px) {
            font-size: remDesktop(18px);
            letter-spacing: 0.25px;
            color: var(--gray-100);
          }
        }

        .icon {
          width: 16px;
          height: auto;

          @media screen and (min-width: 768px) {
            width: 24px;
          }
        }
      }
    }

    .register,
    .faq {
      margin: 10px 0;

      p {
        font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
        font-size: remMobile(14px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: var(--gray-100);

        @media screen and (min-width: 768px) {
          font-size: remDesktop(14px);
        }

        a {
          color: var(--primary);
        }
      }
    }
  }

  .ModalLogin__modal-container {
    padding-top: 0px;
    p {
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      font-size: remMobile(16px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0.15px;
      color: var(--gray-100);
      margin: 0 0 10px 0;

      @media screen and (min-width: 768px) {
        font-size: remDesktop(18px);
      }
    }

    .link-email {
      font-family: 'UnimedSans-2020-SemiBold', Arial, sans-serif;
      font-size: remMobile(16px);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: var(--green-dark-200);
      margin: 24px 0;
      display: block;

      @media screen and (min-width: 768px) {
        font-size: remDesktop(18px);
      }
    }

    .send-another-email {
      span,
      p {
        font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
        font-size: remMobile(14px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: var(--white);

        @media screen and (min-width: 768px) {
          font-size: remDesktop(16px);
        }
      }
    }
  }

  .submit-button {
    width: 100%;
  }
}
