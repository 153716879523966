.card-phone {
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 6px auto;
  padding: 15px;
  border-radius: 16px;
  transition: all .2s ease;

  &:hover {
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
  }

  @media screen and (min-width: 768px) {
    height: 100%;
  }

  @media screen and (min-width: 992px) {
    margin: 0;
  }

  .paper-header {
    margin-bottom: 10px;

    .card-phone-icon {
      width: 16px;
      height: auto;
      margin-bottom: 10px;
      
      @media screen and (min-width: 517px) {
        width: 28px;
      }
    }

    @media screen and (min-width: 768px) {
      i {
        &:before {
          font-size: 1.8rem;
        }
      }
    }
  }

  .paper-body {
    font-size: 1rem;
    font-family: "UnimedSans-2020-Regular", Arial, Helvetica, sans-serif;

    @media screen and (min-width: 517px) {
      margin-bottom: 20px;
    }
  }

  .paper-footer {
    margin-top: 8px;
    font-size: 1.222rem;
    font-family: "UnimedSans-2020-Bold", Arial, sans-serif;
    
    @media screen and (min-width: 517px) {
      margin-top: auto;
    }
  }

  &.paper-primary {
    border: 1px solid var(--primary);
    color: var(--primary);

    svg {
      fill: var(--primary);

      g, path {
        fill: var(--primary);

        path {
          fill: var(--primary);

          &:last-of-type {
            fill: var(--primary);
          }
        }
      }
    }

    &:hover {
      background-color: var(--primary);
      color: var(--white);

      svg {
        fill: var(--white);
  
        g, path {
          fill: var(--white);
  
          path {
            fill: var(--white);
  
            &:last-of-type {
              fill: var(--white);
            }
          }
        }
      }
    }
  }

  &.paper-secondary {
    border: 1px solid var(--secondary);
    color: var(--secondary);

    svg {
      fill: var(--secondary);

      g, path {
        fill: var(--secondary);

        path {
          fill: var(--secondary);

          &:last-of-type {
            fill: var(--secondary);
          }
        }
      }
    }

    &:hover {
      background-color: var(--secondary);
      color: var(--white);

      svg {
        fill: var(--white);
  
        g, path {
          fill: var(--white);
  
          path {
            fill: var(--white);
  
            &:last-of-type {
              fill: var(--white);
            }
          }
        }
      }
    }
  }

  &.paper-purple {
    border: 1px solid var(--purple);
    color: var(--purple);

    svg {
      fill: var(--purple);

      g, path {
        fill: var(--purple);

        path {
          fill: var(--purple);

          &:last-of-type {
            fill: var(--purple);
          }
        }
      }
    }

    &:hover {
      background-color: var(--purple);
      color: var(--white);

      svg {
        fill: var(--white);
  
        g, path {
          fill: var(--white);
  
          path {
            fill: var(--white);
  
            &:last-of-type {
              fill: var(--white);
            }
          }
        }
      }
    }
  }

  &.paper-outline-white,
  &.paper-white {
    background-color: transparent;
    border: 1px solid var(--white);
    color: var(--white);

    @media screen and (min-width: 768px) {
      .paper-footer {
        font-size: 1.777rem;
      }
    }
  }
}
