@import 'styles/sizes';

.share-buttons {
  &__button {
    width: 100%;

    @media (min-width: $grid-md) {
      width: max-content;
    }
  }
}
